import React from "react";
import SidebarMenuItemFront from "./SidebarMenuItemFront";

const SidebarMenuFront = ({ menuTitle, menuData, isMenuOpen, ...props }) => {
  return (
    <>
      <div className="relative py-4 px-4 space-y-2 transition-all duration-100">
        {!isMenuOpen && <div className={"text-xs uppercase text-slate-500 font-md"}>{menuTitle}</div>}
        <div className="space-y-3 -mx-1">
          {menuData.map((item) => (
            <SidebarMenuItemFront
              key={item._id}
              menuLink={item.menuLink}
              menuIcon={item.menuIcon}
              menuIconColor={item.menuIconColor}
              menuLabel={item.menuLabel}
              isMenuOpen={isMenuOpen}
            />
          ))}
        </div>
      </div>
    </>
  );
};

export default SidebarMenuFront;
