import React from 'react';
import { classNames } from "../../helpers/classNames";
import Image from "./Image";
import Member01 from "../../assets/images/member-01.webp";
import Member02 from "../../assets/images/member-02.webp";
import Member03 from "../../assets/images/member-03.webp";
import Member04 from "../../assets/images/member-04.webp";
import Member05 from "../../assets/images/member-04.webp";

const MiniCard = ({ styleType }) => {
  const cardContainer = {
    styleOne: "!border-teal-500 !bg-teal-500",
    styleTwo: "!border-green-500 !bg-green-500",
    styleThree: "!border-orange-500 !bg-orange-500",
    styleFour: "!border-slate-200 !bg-slate-100",
    styleFive: "!border-slate-200 !bg-slate-100",
  }

  const cardHeader = {
    styleOne: "!border-slate-900 !bg-slate-900",
    styleTwo: "!border-slate-900 !bg-slate-900",
    styleThree: "!border-slate-900 !bg-slate-900",
    styleFour: "!border-green-500 !bg-green-500",
    styleFive: "!border-orange-500 !bg-orange-500",
  }

  const cardImage = {
    styleOne: Member01,
    styleTwo: Member02,
    styleThree: Member03,
    styleFour: Member04,
    styleFive: Member05,
  }

  return (
    <>
      <div className="relative">
        <div className={classNames("w-full flex-shrink-0 border rounded px-1 py-1 space-y-1 shadow", cardContainer[styleType])}>
          <div className={classNames("h-4 flex items-center justify-center rounded", cardHeader[styleType])}>
            <div className="text-[6px] text-white !leading-none">Member Name</div>
          </div>
          <div className="flex space-x-1">
            <div className="w-6 h-6 overflow-hidden rounded-full flex-shrink-0 border border-white shadow">
              <Image
                src={cardImage[styleType]}
                alt={""}
                effect={"blur"}
                className={"object-contain"}
              />
            </div>
            <div className="w-full flex-shrink text-slate-900 py-1 space-y-0.5 text-left">
              <div className="text-[4px] !leading-none"><strong>Title:</strong> Designation</div>
              <div className="text-[4px] !leading-none"><strong>ID:</strong> XXXXXXXX</div>
              <div className="text-[4px] !leading-none"><strong>DOB:</strong> 000-XXX-XXXX</div>
              <div className="text-[4px] !leading-none"><strong>Email:</strong> email@orgchart.com</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MiniCard;