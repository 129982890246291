import React, { useState } from "react";
import Button from "../form/Button";
import remove from "../../assets/images/remove.png";
import remove1 from "../../assets/images/remove1.png";
import Modal from "../modal/Modal";
import Image from "../elements/Image";

const DeleteUserModal = ({
  open = false,
  onClose = () => {},
  deleteHandler = () => {},
  title = "Remove Position",
  titleIcon = "fa-regular fa-circle-exclamation",
  loading = false,
  customMessage,
  selectedImage,
  setSelectedImage = () => {},
  hasChild,
}) => {
  const handleImageClick = (image) => {
    setSelectedImage(image);
  };
  // console.log(isChild, "isChildisChildisChild");
  return (
    <>
      {hasChild ? (
        <>
          <Modal
            open={open}
            onClose={() => {
              onClose();
              setSelectedImage("single");
            }}
            title={title}
            size="xl"
          >
            <div className="p-4">
              <div className="text-center text-org-theme">
                {customMessage ? (
                  customMessage
                ) : (
                  <>This position manages others below it. How would you like to proceed?</>
                )}
              </div>

              <div className="flex justify-center gap-5 mt-6 border-b border-org-border py-4">
                <div
                  onClick={() => handleImageClick("single")}
                  className={`cursor-pointer border-2 rounded-xl overflow-hidden ${
                    selectedImage === "single" ? " border-org-theme " : " border-gray-200 "
                  }`}
                >
                  <Image src={remove} alt={remove} className={"object-contain"} />
                </div>
                <div
                  onClick={() => handleImageClick("multiple")}
                  className={`cursor-pointer border-2 rounded-xl overflow-hidden ${
                    selectedImage === "multiple" ? " border-org-theme " : " border-gray-200 "
                  }`}
                >
                  <Image src={remove1} alt={remove1} className={"object-contain"} />
                </div>
              </div>

              <div className="flex gap-3 justify-end mt-3">
                <Button
                  buttonIconPosition={"left"}
                  buttonIcon={"fa-regular fa-close"}
                  buttonLabel={"Cancel"}
                  buttonFunction={() => {
                    onClose();
                    setSelectedImage("single");
                  }}
                  buttonClasses={"!bg-white !text-org-theme !border-org-theme !hover:bg-org-theme/80"}
                />
                <Button
                  buttonFunction={deleteHandler}
                  buttonIconPosition={"left"}
                  buttonIcon={loading ? "fa-duotone fa-spinner-third animate-spin" : "fa-regular fa-trash-can"}
                  buttonLabel={"Remove"}
                  buttonClasses={"!bg-org-red !text-white !border-org-red !hover:bg-org-red/80"}
                />
              </div>
            </div>
          </Modal>
        </>
      ) : (
        <>
          <Modal
            open={open}
            onClose={() => {
              onClose();
              setSelectedImage("single");
            }}
            title={"Delete Record"}
            size="sm"
          >
            <div className="text-center my-3 text-slate-500">
              {customMessage ? (
                customMessage
              ) : (
                <>
                  You are trying to delete the record,
                  <br /> click &quot;Delete&quot; below.
                </>
              )}
            </div>
            <div className="flex gap-3 justify-center">
              <Button
                buttonIconPosition={"left"}
                buttonIcon={"fa-regular fa-close"}
                buttonLabel={"Cancel"}
                buttonFunction={() => {
                  onClose();
                  setSelectedImage("single");
                }}
                buttonClasses={"!bg-org-green !border-org-green !hover:bg-org-green/80"}
              />
              <Button
                buttonFunction={deleteHandler}
                buttonIconPosition={"left"}
                isDisable={loading}
                isLoaderDisabled={true}
                buttonIcon={loading ? "fa-duotone fa-spinner-third animate-spin" : "fa-regular fa-trash-can"}
                buttonLabel={"Delete"}
                buttonClasses={"!bg-org-red !border-org-red !hover:bg-org-red/80"}
              />
            </div>
          </Modal>
        </>
      )}
    </>
  );
};

export default DeleteUserModal;
