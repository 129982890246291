import React from "react";

const Loader = ({ className }) => {
  return (
    <div className="flex flex-col items-center">
      <div
        className={
          "w-28 h-28 rounded-full bg-white shadow flex items-center justify-center loader-circle " +
          className
        }
      ></div>
      <span className="text-2xl font-bold mt-4 text-victoria">Loading...</span>
    </div>
  );
};

export default Loader;
