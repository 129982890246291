import React, { useState } from "react";
import Button from "../components/form/Button";
import OtpInput from "../components/form/OtpInput";
import useForm from "../hooks/useForm";
import { formValidate } from "../helpers/formValidate";
import { verifyOtp } from "../services/ProfileService";
import { toast } from "react-toastify";
const validation = {};

const VerifyOtpForm = ({
  email,
  setEmail = () => {},
  setIsVerified = () => {},
  isVerified,
  isOtp,
  setIsOtp = () => {},
  verifiedOtpData,
  setVerifiedOtpData = () => {},
}) => {
  const [loaded, setLoaded] = useState(true);
  const [otp, setOtp] = useState(null);
  const onVerifyOtp = async (values) => {
    setLoaded(false);
    try {
      await verifyOtp({
        otp: otp,
        email: email,
      }).then((res) => {
        if (res?.status === 200) {
          toast.success("OTP Verified Successfull");
          setVerifiedOtpData(otp);
          setEmail(email);
          setIsVerified(true);
          setIsOtp(false);
        } else {
          toast.error(res?.data?.message);
          setIsVerified(false);
          setIsOtp(true);
        }
      });
      setLoaded(true);
    } catch (error) {
      // console.error("Error adding category:", error);
      setLoaded(true);
      toast.error(error);
    }
  };
  return (
    <>
      <form>
        <div>
          <div className="mt-2">
            <OtpInput length={6} handleOtpChange={(e) => setOtp(e)} />
          </div>
        </div>
        <div className="mt-2">
          <Button
            buttonClasses={"w-full text-white  hover:bg-primary-two focus-visible:outline  justify-center rounded-md"}
            buttonHasLink={false}
            buttonType={"button"}
            buttonLabel={"Verify OTP"}
            buttonFunction={onVerifyOtp}
          />
        </div>
      </form>
    </>
  );
};
export default VerifyOtpForm;
