import React, { useEffect, useState } from "react";
import Image from "./Image";
import { classNames } from "../../helpers/classNames";
import UserDropdown from "./UserDropdown";
import Member from "../../assets/images/noData.png";
import moment from "moment";
import Button from "../form/Button";

const Card = ({
  styleType,
  randomId,
  details,
  userList,
  userKeyword,
  setUserKeyword = () => {},
  handleChange = () => {},
  selectedUserValue,
  handleEdit = () => {},
  handleViewDetails = () => {},
  onDelete = () => {},
  setDeleteModal = () => {},
  treeIds,
  addNewEmployee = () => {},
  setSelectedUserValue = () => {},
  ref_attr,
}) => {
  const [employeeModalOpen, setEmployeeModalOpen] = useState({ isOpen: false });
  const cardContainer = {
    styleOne: "!border-teal-500 !bg-teal-500",
    styleTwo: "!border-green-500 !bg-green-500",
    styleThree: "!border-orange-500 !bg-orange-500",
    styleFour: "!border-slate-200 !bg-slate-100",
    styleFive: "!border-slate-200 !bg-slate-100",
    styleDefault: "!border-slate-200 !bg-slate-100",
  };

  const cardHeader = {
    styleOne: "!border-slate-900 !bg-slate-900",
    styleTwo: "!border-slate-900 !bg-slate-900",
    styleThree: "!border-slate-900 !bg-slate-900",
    styleFour: "!border-green-500 !bg-green-500",
    styleFive: "!border-orange-500 !bg-orange-500",
    styleDefault: "!border-blue-500 !bg-blue-500",
  };

  const isAnyDetailNA = !details?.userDetail?.name;
  const cardStyle = isAnyDetailNA ? "styleDefault" : styleType;

  const customBackgroundColor = details?.colorShade?.backgroundColor;
  const customTextColor = details?.colorShade?.textColor;
  const customTabColor = details?.colorShade?.tabColor;
  const customTabTextColor = details?.colorShade?.headingColor;

  return (
    <div className="relative">
      <div
        className={classNames(
          "w-80 flex-shrink-0 border rounded-md px-2 py-2 space-y-2 shadow-md",
          !customBackgroundColor && cardContainer[cardStyle]
        )}
        style={customBackgroundColor ? { backgroundColor: customBackgroundColor } : {}}
      >
        {cardStyle === "styleDefault" && (
          <div className="absolute top-0 left-0 z-0 w-full h-full flex items-center justify-center text-black opacity-25 text-6xl pt-8">
            <i className="fa-light fa-fw fa-shield-exclamation"></i>
          </div>
        )}
        <div className="relative">
          <UserDropdown
            xPlacement={"bottom"}
            buttonArrowIcon={""}
            isSearch={true}
            dropdownButtonClass={classNames("!px-3 !justify-start", !customTabColor && cardHeader[cardStyle])}
            dropdownButtonStyle={customTabColor ? { backgroundColor: customTabColor, color: customTabTextColor } : {}}
            tabTextColor={customTabTextColor}
            onChange={(value) => {
              handleChange(value);
            }}
            notSelectedItem={treeIds}
            onSearch={(key) => setUserKeyword(key)}
            // selectedValue={{
            //   _id: selectedUserValue,
            //   name: `${details?.userDetail?.name || "Select User"}`,
            // }}
            selectedValue={selectedUserValue}
            dropdownData={userList?.map((item) => ({
              name: item?.name,
              email: item?.email,
              _id: item?._id,
            }))}
            setEmployeeModalOpen={setEmployeeModalOpen}
            employeeModalOpen={employeeModalOpen}
            addNewEmployee={addNewEmployee}
            details={details}
          />
          {(details?.parentId || details?.userDetail?.name) && (
            <div className="absolute top-2 right-0 z-[1]">
              <div className="flex flex-grow">
                {details?.userDetail?.name && (
                  <Button
                    buttonIcon={"fa-light fa-pen"}
                    buttonIconPosition={"left"}
                    buttonClasses={"!w-full !bg-transparent !border-transparent !text-white !text-xs !w-6 !h-6"}
                    buttonFunction={() => handleEdit(details)}
                  />
                )}
                <Button
                  buttonIcon={"fa-sharp fa-solid fa-info"}
                  buttonIconPosition={"left"}
                  buttonClasses={"!w-full !bg-transparent !border-transparent !text-white !text-xs !w-6 !h-6"}
                  buttonFunction={() => handleViewDetails(details)}
                />
                {details?.parentId && (
                  <Button
                    buttonIcon={"fa-light fa-trash"}
                    buttonIconPosition={"left"}
                    buttonClasses={"!w-full !bg-transparent !border-transparent !text-white !text-xs !w-6 !h-6"}
                    buttonFunction={() =>
                      setDeleteModal({
                        isOpen: true,
                        id: details?._id,
                        hasChild: details?.hasChild,
                      })
                    }
                  />
                )}
              </div>
            </div>
          )}
        </div>
        <div className="flex space-x-4 relative z-0">
          <div
            className="w-20 h-20 overflow-hidden rounded-full flex-shrink-0 -mb-10 border-2 border-white shadow-md"
            // onClick={() => handleViewDetails(details)}
          >
            <Image
              src={details?.userDetail?.image || Member}
              // src={"https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885_1280.jpg"}
              alt={""}
              effect={"blur"}
              className={"object-contain"}
            />
          </div>
          <div
            className={classNames("w-full flex-shrink py-1 space-y-1", customTextColor ? "" : "text-slate-900")}
            style={customTextColor ? { color: customTextColor } : {}}
          >
            {/* <div className="text-xs">
              <strong>Email:</strong> {details?.userDetail?.email || "N/A"}
            </div> */}

            <div className="text-xs">
              <strong>Title:</strong> {details?.userDetail?.positionDetail?.title || "N/A"}
            </div>
            {/* <div className="text-xs">
              <strong>DOB:</strong>{" "}
              {details?.userDetail?.dateOfBirth
                ? moment(new Date(details?.userDetail?.dateOfBirth)).format("Do MMMM, YYYY")
                : "N/A"}
            </div>
            <div className="text-xs">
              <strong>Phone:</strong> {details?.userDetail?.phone || "N/A"}
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Card;
