import React, { useCallback, useEffect, useRef, useState } from "react";
import { setTitle } from "../helpers/MetaTag";
import { NoPanArea, Space } from "react-zoomable-ui";
import Button from "../components/form/Button";
import CardWrapper from "../components/elements/CardWrapper";
import useWindowDimensions from "../helpers/useWindowDimensions";
import Draggable from "react-draggable";
import { deleteRelation, getColorList, getRelationList, getUserList } from "../services/HomeService";
import Xarrow, { useXarrow, Xwrapper } from "react-xarrows";
import Loader from "../components/elements/loader/Loader";
import Modal from "../components/modal/Modal";
import UserDetails from "../components/userDetails/UserDetails";
import { toast } from "react-toastify";
import UserEdit from "../components/userDetails/UserEdit";
import DeleteModal from "../components/modal/DeleteModal";
import DeleteUserModal from "../components/modal/DeleteUserModal";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { companyDetails } from "../services/adminService/UserService";

const AdminChart = () => {
  const { id } = useParams();
  setTitle("ORG CHART | Home");
  const dispatch = useDispatch();
  const [list, setList] = useState({ loading: true, data: [] });
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [status, setStatus] = useState("all");
  const [mainIdForZIndex, setMainIdForZIndex] = useState(null);
  const spaceRef = useRef();
  const { height, width } = useWindowDimensions();
  const [xPlacement, setXPlacement] = useState(null);
  const [renderData, setRenderData] = useState();
  const [isChanged, setIschanged] = useState(false);
  const [mainId, setMainId] = useState();
  const [selectStyles, setSelectStyles] = useState({});
  const [positions, setPositions] = useState({ x: 0, y: 0 });
  const [tree, setTree] = useState(list && list?.data);
  const [userLimit, setUserLimit] = useState(10);
  const [userOffset, setUserOffset] = useState(0);
  const [userKeyword, setUserKeyword] = useState("");
  const [userStatus, setUserStatus] = useState("active");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [userDetails, setUserDetails] = useState({});
  const [detailsData, setDetailsData] = useState("");
  const updateXarrow = useXarrow();
  //   const profile = useSelector((state) => state.profile.profileData);
  const searchValue = useSelector((state) => state?.search?.data);

  const [deleteModal, setDeleteModal] = useState({
    isOpen: false,
  });

  const [deleteComplete, setDeleteComplete] = useState(true);
  const [selectedImage, setSelectedImage] = useState("single");
  const handleCloseDeleteModal = () => {
    setDeleteModal({ isOpen: false });
    setSelectedImage(null);
  };
  //   const companyData = profile?.companyDetail && profile?.companyDetail.length > 0 && profile?.companyDetail[0];

  // console.log(companyData, "companyData");

  const [userList, setUserList] = useState({
    loading: true,
    data: [],
    pageCount: 0,
    totalItem: 0,
  });

  const [colorList, setColorList] = useState({
    loading: true,
    data: [],
    pageCount: 0,
    totalItem: 0,
  });
  const renderTreeForZIndex = (data, id) => {
    if (data?.length) {
      for (const item of data) {
        item.zIndex = 0;
      }
      for (const item of data) {
        if (item._id === id) {
          item.zIndex = 9;
        }
      }
    }
  };

  useEffect(() => {
    setIschanged(false);
  }, [tree]);

  //For z index
  useEffect(() => {
    if (mainIdForZIndex) {
      renderTreeForZIndex(tree, mainIdForZIndex);
      setTree(tree);
      setIschanged(true);
      setMainIdForZIndex(null);
    }
  }, [mainIdForZIndex]);

  // Loading the list >>>>>>>>>
  const loadList = useCallback(() => {
    const itemIdForZIndex = localStorage.getItem("itemIdForZIndex");

    setList((pre) => ({ ...pre, loading: true }));
    getRelationList({
      offset,
      limit: 999999999,
      id: id,
    }).then((res) => {
      if (res?.docs?.success) {
        setList({
          loading: false,
          data: res.docs?.data,
        });
        setTree(res.docs?.data);
        if (itemIdForZIndex) {
          setTree((prevTree) => {
            return prevTree.map((item) => {
              if (item._id === itemIdForZIndex) {
                return {
                  ...item,
                  zIndex: 9,
                };
              }
              return item;
            });
          });
        }
      } else {
        setList((pre) => ({ ...pre, loading: false }));
      }
    });
  }, [limit, offset]);

  useEffect(() => {
    loadList();
  }, [loadList]);

  // Loading User list >>>>>>>>>
  const loadUserList = useCallback(() => {
    if (userStatus && userLimit) {
      setUserList((pre) => ({ ...pre, loading: true }));
      if (id) {
        getUserList({
          keyword: userKeyword,
          offset,
          limit: 999999999,
          sortQuery: "-createdAt",
          // roleCode: "employee",
          companyId: id,
          status: ["active"],
        }).then((res) => {
          if (res?.docs?.success) {
            setUserList({
              loading: false,
              data: res?.docs?.data,
            });
          } else {
            setUserList((pre) => ({ ...pre, loading: false }));
          }
        });
      }
    }
  }, [userLimit, userKeyword, userOffset, tree, id]);

  useEffect(() => {
    loadUserList();
  }, [loadUserList]);

  const getDetails = useCallback(() => {
    if (id) {
      setDetailsData({ loading: true, data: {} });
      companyDetails({ id }).then((res) => {
        if (res?.status === 200) {
          setDetailsData({ loading: false, data: res?.docs });
        } else {
          setDetailsData({ loading: false, data: {} });
        }
      });
    }
  }, [id]);

  useEffect(() => {
    getDetails();
  }, [getDetails]);
  const loadColorList = useCallback(() => {
    setColorList((pre) => ({ ...pre, loading: true }));
    getColorList({
      offset,
      limit: 999,
    }).then((res) => {
      if (res?.docs?.success) {
        setColorList({
          loading: false,
          data: res?.docs?.data,
        });
      } else {
        setColorList((pre) => ({ ...pre, loading: false }));
      }
    });
  }, []);

  useEffect(() => {
    loadColorList();
  }, [loadColorList]);

  const [cardWidth, setCardWidth] = useState(320);

  const viewUserDetails = (item) => {
    if (item?.userDetail?.email) {
      setIsModalOpen(true);
      setUserDetails(item);
    } else {
      setIsModalOpen(false);
      setUserDetails({});
      toast.warning("No Profile Data");
    }
  };

  const EditDetails = (item) => {
    if (item) {
      setEditModalOpen(true);
      setUserDetails(item);
    } else {
      setEditModalOpen(false);
      setUserDetails({});
      // toast.warning("No Profile Data");
    }
  };

  const handleViewDetails = (item) => {
    viewUserDetails(item);
  };

  const handleEdit = (item) => {
    EditDetails(item);
  };

  // Delete Data funtcion
  const onDelete = (item) => {
    setDeleteComplete(false);
    if (deleteModal?.id) {
      if (deleteModal?.hasChild) {
        try {
          deleteRelation({
            id: deleteModal?.id,
            deletationStatus: selectedImage,
            companyId: id,
          }).then((res) => {
            if (res?.status === 200) {
              toast?.success(res?.message);
              // setTree((pre) => pre?.filter((item) => item._id !== deleteModal?.id));
              setDeleteModal({
                isOpen: false,
              });
              setSelectedImage(null);
              loadList();
            } else {
              toast.error(res?.message || res?.error);
            }
          });
          setDeleteComplete(true);
        } catch (error) {
          setDeleteComplete(true);
          toast.error(error);
        }
      } else {
        try {
          deleteRelation({
            id: deleteModal?.id,
            deletationStatus: "single",
            companyId: id,
          }).then((res) => {
            if (res?.status === 200) {
              toast?.success(res?.message);
              // setTree((pre) => pre?.filter((item) => item._id !== deleteModal?.id));
              setDeleteModal({
                isOpen: false,
              });
              setSelectedImage(null);
              loadList();
            } else {
              toast.error(res?.message || res?.error);
            }
          });
          setDeleteComplete(true);
        } catch (error) {
          setDeleteComplete(true);
          toast.error(error);
        }
      }
    }
  };

  // Step 1: Extract the IDs from the tree
  const treeIds = tree?.map((item) => item?.userId);

  // Step 2: Filter the userList to remove entries with IDs that exist in the tree
  const updatedUserList = userList?.data?.filter((item) => !treeIds.includes(item._id));

  const addNewEmployee = (data) => {
    setEditModalOpen(true);
    setUserDetails({ cardId: data?._id });
  };

  return (
    <>
      <div className="relative h-screen w-full bg-white-200">
        {/* <div className="relative flex items-center justify-between">
          <Button
            buttonClasses={"focus-visible:outline justify-center rounded-md"}
            buttonHasLink={true}
            buttonType={"button"}
            buttonLabel={"Back"}
            buttonLink={"/admin/companies"}
            buttonIconPosition={"left"}
            buttonIcon={"fa-duotone fa-backward"}
          />
          <h4 className="flex-grow text-center text-5xl text-slate-500">{detailsData?.data?.name}</h4>
        </div> */}
        {/* <Space ref={spaceRef} onCreate={(vp) => vp.camera.recenter(width / 2, height / 2, 1)}>
          <NoPanArea> */}
        <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 ">
          <Xwrapper>
            {tree.map((item, index) => {
              let positionStyle = "absolute";
              return (
                <div
                  key={index + item._id}
                  className={positionStyle}
                  style={{
                    zIndex:
                      searchValue &&
                      item?.userDetail?.name
                        ?.replace(/\s+/g, "")
                        ?.toLowerCase()
                        .includes(searchValue?.replace(/\s+/g, "")?.toLowerCase())
                        ? 100
                        : item?.zIndex,
                  }}
                >
                  <CardWrapper
                    setXPlacement={setXPlacement}
                    randomId={item?._id}
                    setMainId={setMainId}
                    style={item?.style}
                    setSelectStyles={setSelectStyles}
                    positions={{
                      x: item?.coordinateArrayLargeDevice.x_axis,
                      y: item?.coordinateArrayLargeDevice.y_axis,
                    }}
                    setPositions={setPositions}
                    setMainIdForZIndex={setMainIdForZIndex}
                    loadList={loadList}
                    setTree={setTree}
                    tree={tree}
                    details={item}
                    xPlacement={xPlacement}
                    userList={userList?.data}
                    colorList={colorList?.data}
                    userKeyword={userKeyword}
                    setUserKeyword={setUserKeyword}
                    cardWidth={cardWidth}
                    setCardWidth={setCardWidth}
                    handleViewDetails={handleViewDetails}
                    handleEdit={handleEdit}
                    setDeleteModal={setDeleteModal}
                    treeIds={treeIds}
                    searchValue={searchValue}
                    addNewEmployee={addNewEmployee}
                    companyId={id}
                  />
                  {index > 0 && (
                    <>
                      <Xarrow
                        key={index}
                        start={item?._id}
                        end={item?.parentId}
                        color="grey"
                        curveness={0}
                        path="grid"
                        startAnchor={"mid"}
                        endAnchor={"mid"}
                        strokeWidth="3"
                        showHead={false}
                        showTail={false}
                      />
                    </>
                  )}
                </div>
              );
            })}
          </Xwrapper>
        </div>
        {/* </NoPanArea>
        </Space> */}

        {/* <div className="absolute bottom-0 right-0 z-10 px-3 py-3 space-y-[-1px]">
          <Button
            buttonHasLink={false}
            buttonIcon={"fa-regular fa-plus"}
            buttonIconPosition={"left"}
            buttonFunction={() => {
              setCardWidth((prev) => prev + 80);
              spaceRef.current?.viewPort?.camera.moveBy(0, 0, 0.25);
            }}
            buttonClasses={"!h-8 !w-8 !px-0 !rounded-none"}
          />
          <Button
            buttonHasLink={false}
            buttonIcon={"fa-regular fa-arrows-to-dot"}
            buttonIconPosition={"left"}
            buttonFunction={() => spaceRef.current?.viewPort?.camera.recenter(width / 2, height / 2, 1)}
            buttonClasses={"!h-8 !w-8 !px-0 !rounded-none"}
          />
          <Button
            buttonHasLink={false}
            buttonIcon={"fa-regular fa-minus"}
            buttonIconPosition={"left"}
            buttonFunction={() => {
              setCardWidth((prev) => prev - 80);
              spaceRef.current?.viewPort?.camera.moveBy(0, 0, -0.25);
            }}
            buttonClasses={"!h-8 !w-8 !px-0 !rounded-none"}
          />
        </div> */}
      </div>
      <Modal
        title={"User details"}
        open={isModalOpen}
        size="lg"
        onClose={() => {
          setIsModalOpen(false);
          setUserDetails({});
        }}
      >
        <UserDetails setIsModalOpen={setIsModalOpen} data={userDetails} setUserDetails={setUserDetails} />
      </Modal>
      <Modal
        title={userDetails?._id ? "Edit User" : "Add New User"}
        open={editModalOpen}
        size="xl2"
        onClose={() => {
          setEditModalOpen(false);
          setUserDetails({});
        }}
      >
        <UserEdit
          tree={tree}
          setEditModalOpen={setEditModalOpen}
          data={userDetails}
          setUserDetails={setUserDetails}
          colorList={colorList}
          setColorList={setColorList}
          loadList={loadList}
          companyId={id}
        />
      </Modal>

      <DeleteUserModal
        open={deleteModal?.isOpen}
        onClose={handleCloseDeleteModal}
        loading={!deleteComplete}
        deleteHandler={onDelete}
        selectedImage={selectedImage}
        setSelectedImage={setSelectedImage}
        hasChild={deleteModal?.hasChild}
      />
    </>
  );
};

export default AdminChart;
