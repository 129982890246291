import React, { useCallback, useEffect, useState } from "react";
import Image from "../../../components/elements/Image";
import Input from "../../../components/form/Input";
import Indentification from "../../../components/elements/Indentification";
import { useNavigate } from "react-router-dom";
import { useDebounce } from "../../../helpers/useDebounce";
import { useSelector } from "react-redux";
import { getAdminList } from "../../../services/adminService/UserService";
import { toast } from "react-toastify";
import { EditEmployeeForm } from "./EditEmployeeForm";
import { setTitle } from "../../../helpers/MetaTag";

const CompanyDetails = () => {
  setTitle("ORG CHART | View Details");
  const navigate = useNavigate();
  const [limit, setLimit] = useState({ name: "10 Items", value: 10 });
  const [status, setStatus] = useState("all");
  const [featured, setFeatured] = useState(" ");
  const [offset, setOffset] = useState(0);
  const [sort, setSort] = useState("-createdAt");
  const [currentPage, setCurrentPage] = useState(1);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [userDetails, setUserDetails] = useState({});
  const [pagination, setPagination] = useState({
    hasNextPage: false,
    hasPrevPage: false,
  });
  const [keyword, setKeyword] = useState("");
  const search = useDebounce(keyword, 300);
  const [list, setList] = useState({
    loading: true,
    data: [],
    pageCount: 0,
    totalItem: 0,
  });
  const [count, setCount] = useState(0);
  const [selectedItems, setSelectedItems] = useState([]);
  // const [statusFilterData, setStatusFilterData] = useState(status_filter || []);
  const [deleteModal, setDeleteModal] = useState({
    isOpen: false,
    id: null,
  });
  const [selectedItem, setSelectedItem] = useState(
    list && list?.data && list?.data?.length > 0 && list?.data[0]?._id
  );
  const [deleteComplete, setDeleteComplete] = useState(true);
  const [selectedRoleValue, setSelectedRoleValue] = useState({
    name: "Employee",
    value: "employee",
  });
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [csvModalOpen, setCsvModalOpen] = useState(false);
  const profile = useSelector((state) => state.profile.profileData);

  // loading the member list >>>>>>>>>
  const loadList = useCallback(() => {
    setList((pre) => ({ ...pre, data: [], loading: true }));
    const statusData = {};
    if (status && Array.isArray(status) && status?.length > 0) {
      status?.forEach((item, i) => (statusData[`status[${i}]`] = item));
    }
    getAdminList({
      sortQuery: sort,
      keyword: search,
      limit: 10000,
      page: currentPage,
      ...statusData,
    }).then((res) => {
      if (res && res?.docs?.success) {
        setList({
          loading: false,
          data: res.docs?.data,
          pageCount: res?.docs?.metadata?.totalPages,
          keyword: keyword,
          totalItem: res?.docs?.metadata?.totalDocs,
        });
        setSelectedItem(
          res.docs?.data && res.docs?.data?.length > 0 && res.docs?.data[0]?._id
        );
        setCount(res?.docs?.metadata?.totalDocs);
        setPagination({
          hasNextPage: res?.docs?.metadata?.hasNextPage,
          hasPrevPage: res?.docs?.metadata?.hasPrevPage,
        });
      } else {
        setList((pre) => ({ ...pre, data: [], loading: false }));
        toast.error(res?.message);
      }
    });
  }, [limit, status, sort, featured, search, currentPage, count]);

  useEffect(() => {
    loadList();
  }, [loadList]);

  const handleItemClick = (id) => {
    setSelectedItem(id);
  };

  return (
    <>
      <section className="flex flex-col lg:flex-row  justify-center items-center">
        <div className="flex flex-col justify-center items-start p-6 mr-8 lg:w-2/4">
          <h1 className="md:text-5xl text-2xl font-bold text-gray-800 leading-snug mb-4">
            PWTS Private Limited
          </h1>
        </div>
        {/* <div className="flex flex-wrap justify-center mr-18 mx-auto md:flex-nowrap mt-6 lg:mt-0 lg:w-2/4 border-t pt-12 lg:border-t-0 lg:pt-0">
          <div className="w-full md:w-1/4 p-4">
            <div className=" shadow-xl mx-auto -mt-10 h-64 w-64 bg-cover bg-center relative">
              <Image
                src={
                  "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSblZ9YNv6gwVyunqGwUw2mrZq9G1Gu8FhRfQ&s"
                }
                fill={true}
                alt=""
              />
            </div>
          </div>
        </div> */}
      </section>
      <div className="block sm:flex  gap-2 space-y-4 sm:space-y-0">
        <div className="w-full lg:w-4/12 sm:w-3/6 border rounded-t-md bg-org-silver ">
          <div className="bg-org-theme rounded-t-md h-10 flex justify-center items-center">
            <p className="text-white py-6">List Of Employees</p>
          </div>
          <div className="flex flex-col items-center justify-between relative w-full  gap-2 my-2">
            <div className="relative w-full px-2 mt-0">
              <Input
                inputType={"text"}
                isInputGroup={true}
                inputGroupPosition={"left"}
                inputGroupIcon={"fa-regular fa-search"}
                inputPlaceholder={"Search"}
                inputClasses={"!pl-3"}
              />
            </div>
            <div className="relative w-full h-[600px] overflow-auto scroll-smooth  scrollbar ">
            {list &&
              list?.data &&
              list?.data?.length > 0 &&
              list?.data?.map((item, index) => (
                <div
                  className={`relative w-full px-2 cursor-pointer `}
                  key={index}
                  onClick={() => handleItemClick(item._id)}
                >
                  <div
                    className={`relative w-full border border-slate-200 px-2 py-3 rounded-md flex items-start gap-2 ${
                      selectedItem === item?._id
                        ? "border-green-700 border bg-green-100"
                        : "border-slate-200"
                    }`}
                  >
                    <div className="w-14 h-14 overflow-hidden rounded-full shrink-0">
                      <Image
                        src={item?.image?.url || ""}
                        className={"w-full h-full object-cover"}
                        // src={"https://buffer.com/library/content/images/size/w1200/2023/10/free-images.jpg"}
                        fill={true}
                        alt={item?.name || "N/A"}
                      />
                    </div>
                    <div className="relative w-full shrink-1">
                      <div className="realtive flex items-center w-full ">
                        <div className="realtive text-base text-slate-900 font-semibold">
                          {item?.name || "N/A"}
                        </div>
                        <div
                          className={`text-xs font-medium leading-tight rounded py-1 px-2 ml-auto xl:block hidden ${
                            item?.status === "active"
                              ? "bg-green-100 text-green-600 border border-green-600"
                              : "bg-red-100 text-red-600 border-red-600 border"
                          }`}
                        >
                          {item?.status === "active" ? "Active" : "In Active"}
                        </div>
                      </div>
                      <div className="realtive flex flex-col mt-1 text-slate-400">
                        <div className="text-xs  font-normal ">
                          {item?.email || "N/A"}
                        </div>
                        <div className="text-xs  font-normal ">
                          {item?.designation || "N/A"}
                        </div>
                        <div className="text-sm  font-bold text-org-theme">
                          {item?.roleCode === "employee"
                            ? "Employee"
                            : item?.roleCode === "admin"
                            ? "Administrator"
                            : "N/A"}
                        </div>
                        <div
                          className={`text-xs font-medium leading-tight rounded py-1 px-2 ml-auto xl:hidden block ${
                            item?.status === "active"
                              ? "bg-green-100 text-green-600 border border-green-600"
                              : "bg-red-100 text-red-600 border-red-600 border"
                          }`}
                        >
                          {item?.status === "active" ? "Active" : "In Active"}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
              </div>
          </div>
        </div>
        <div className="w-full sm:w-8/12 border rounded-t-md bg-org-silver">
          <div className="bg-org-theme rounded-t-md h-10 flex justify-center items-center">
            <p className="text-white py-6">Edit Employee</p>
          </div>
          <div className="relative">
            <EditEmployeeForm id={selectedItem} setCount={setCount} setList={setList}/>
          </div>
        </div>
      </div>
    </>
  );
};

export default CompanyDetails;
