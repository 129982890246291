import React, { useEffect, useState } from "react";
import siteLogo from "../assets/mylogo.png";
import Input from "../components/form/Input";
import Button from "../components/form/Button";
import useForm from "../hooks/useForm";
import { formValidate } from "../helpers/formValidate";
import { toast } from "react-toastify";
import { SignInService } from "../services/LoginService";
import { useNavigate } from "react-router-dom";
import { sendEmail } from "../services/ProfileService";
import VerifyOtpForm from "./VerifyOtpForm";
import ChangePassword from "./ChangePassword";

const validation = {
  email: { required: true, message: "Email is required" },
};

const ForgotPassword = () => {
  const { values, handleChange, handleSubmit, errors, setFieldsValue, resetField } = useForm({}, validation);
  const backgroundStyle = {
    backgroundColor: "#f1f5f9",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  };
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState({
    color: "red-600",
    message: null,
  });
  const [loaded, setLoaded] = useState(true);
  const [isOtp, setIsOtp] = useState(false);
  const [email, setEmail] = useState();
  const [isVerified, setIsVerified] = useState(false);
  const [verifiedOtpData, setVerifiedOtpData] = useState();
  const [showResendButton, setShowResendButton] = useState(false);
  const [timer, setTimer] = useState(60);

  const onSubmit = async (values) => {
    setLoaded(false);
    try {
      await sendEmail({
        requestType: "RESETPASSWORD",
        email: email,
      }).then((res) => {
        if (res?.status === 200) {
          toast.success("OTP Code Sent To Your Email Id.");
          setIsOtp(true);
          setEmail(email);
          setTimer(60);
          setShowResendButton(true);
        } else {
          toast.error(res?.data?.message);
        }
      });
      setLoaded(true);
    } catch (error) {
      setLoaded(true);
      setIsOtp(false);
      toast.error(error);
    }
  };
  useEffect(() => {
    let interval;
    if (showResendButton && timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTimer) => {
          if (prevTimer === 1) {
            clearInterval(interval);
            setShowResendButton(false); // Hide the button after the timer ends
            return 0;
          }
          return prevTimer - 1;
        });
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [showResendButton, timer]);

  useEffect(() => {
    if (isOtp && !isVerified) {
      setShowResendButton(true);
    }
  }, [isOtp, isVerified]);

  // const cancelChange = () => {
  //   navigate(`/signin`);
  // };
  return (
    <div className="w-full min-h-screen flex items-center justify-center py-3 px-5" style={backgroundStyle}>
      <div className="w-full max-w-md xl:max-w-md bg-white py-8 px-4 rounded-2xl">
        <div className="flex min-h-full flex-1 flex-col justify-center px-2 py-4 lg:px-2">
          <div className="sm:mx-auto sm:w-full sm:max-w-sm">
            <img className="mx-auto h-24 w-auto" src={siteLogo} alt="Your Company" />

            {isOtp && (
              <>
                <h3 className="mt-1 text-center text-xl font-bold leading-9 tracking-tight text-sky-600">Verify OTP</h3>
                <h5 className="mt-1 text-center text-md font-bold leading-9 tracking-tight text-slate-400">
                  Enter Your Otp To Change Your Password
                </h5>
              </>
            )}
            {isVerified && (
              <h3 className="mt-1 text-center text-xl font-bold leading-9 tracking-tight text-sky-600">
                Change Password
              </h3>
            )}
            {!isOtp && !isVerified && (
              <>
                <h3 className="mt-1 text-center text-xl font-bold leading-9 tracking-tight text-sky-600">Send OTP</h3>
                <h4 className="mt-1 text-center text-md font-bold leading-9 tracking-tight text-slate-400">
                  Enter Your Email To Reset Password
                </h4>
              </>
            )}
          </div>

          <div className="mt-2 sm:mx-auto sm:w-full sm:max-w-sm">
            {!isOtp && !isVerified && (
              <>
                <form onSubmit={(event) => handleSubmit(event, onSubmit)}>
                  <div className="mt-2">
                    <Input
                      label={"Email"}
                      labelClasses={"!text-xs"}
                      inputType={"text"}
                      inputPlaceholder={"Enter your email"}
                      value={values.email}
                      inputName={"email"}
                      onChange={(e) => {
                        handleChange(e);
                        setEmail(e.target.value);
                      }}
                      {...formValidate(errors, "email")}
                    />
                  </div>

                  <div className="mt-5 flex justify-between gap-4">
                    <Button
                      buttonClasses={"w-full focus-visible:outline justify-center rounded-md"}
                      buttonHasLink={true}
                      buttonType={"button"}
                      buttonLabel={"Back"}
                      buttonLink={"/signin"}
                      buttonIconPosition={"left"}
                      buttonIcon={"fa-duotone fa-backward"}
                    />
                    <Button
                      buttonClasses={
                        "w-full text-white hover:bg-primary-two focus-visible:outline justify-center rounded-md mr-2"
                      }
                      buttonHasLink={false}
                      buttonType={"submit"}
                      buttonLabel={"Send Otp"}
                    />
                  </div>
                </form>
              </>
            )}

            {isOtp && !isVerified && (
              <>
                <VerifyOtpForm
                  email={email}
                  setEmail={setEmail}
                  isVerified={isVerified}
                  setIsVerified={setIsVerified}
                  isOtp={isOtp}
                  setIsOtp={setIsOtp}
                  verifiedOtpData={verifiedOtpData}
                  setVerifiedOtpData={setVerifiedOtpData}
                />
                <div className="mt-2">
                  <Button
                    buttonClasses={"w-full text-white !bg-sky-700 focus-visible:outline  justify-center rounded-md"}
                    buttonHasLink={false}
                    buttonType={"button"}
                    buttonLabel={"Resend Otp"}
                    buttonFunction={onSubmit}
                    isDisable={showResendButton}
                  />
                </div>
                {showResendButton && <div className="text-center text-red-700 mt-2">Resend OTP in {timer} seconds</div>}
              </>
            )}

            {isVerified && (
              <>
                <ChangePassword
                  verifiedOtpData={verifiedOtpData}
                  setVerifiedOtpData={setVerifiedOtpData}
                  email={email}
                  setEmail={setEmail}
                />
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
