import { apiRequest } from "../util/async/apiUtils";

export const getRelationList = async (params) => {
  try {
    const res = await apiRequest("get", params?.id ? `relation/list/${params?.id}` : "relation/list", {
      queries: {
        ...params,
      },
    });

    return res.data;
  } catch (err) {
    return err?.response;
  }
};

export const addRelation = async (params) => {
  try {
    const res = await apiRequest(
      `${params.id ? "put" : "post"}`,
      `${params.id ? `relation/edit/${params.id}` : "relation/add"}`,
      {
        body: params,
      },
      "multipart/form-data"
    );
    return res?.data;
  } catch (error) {
    return error;
  }
};

export const getUserList = async (params) => {
  try {
    const res = await apiRequest("get", "user/list", {
      queries: {
        ...params,
      },
    });

    return res.data;
  } catch (err) {
    return err?.response;
  }
};

export const getColorList = async (params) => {
  try {
    const res = await apiRequest("get", "shade/list", {
      queries: {
        ...params,
      },
    });

    return res.data;
  } catch (err) {
    return err?.response;
  }
};
export const getUserDetails = async (params) => {
  try {
    const res = await apiRequest("get", `user/details/${params?.id}`);
    return res?.data;
  } catch (err) {
    throw err;
  }
};

export const editUser = async (params) => {
  try {
    const res = await apiRequest(
      "put",
      "relation/edit-user-credential",
      {
        body: params,
      },
      "multipart/form-data"
    );
    return res?.data;
  } catch (error) {
    return error;
  }
};
export const deleteRelation = async (params) => {
  try {
    const res = await apiRequest("patch", `relation/remove/${params?.id}`, {
      body: { ...params },
    });
    return res?.data;
  } catch (error) {
    return error;
  }
};

export const addNewCardUser = async (params) => {
  try {
    const res = await apiRequest(
      "post",
      "relation/quick-user-card",
      {
        body: params,
      },
      "multipart/form-data"
    );
    return res?.data;
  } catch (error) {
    return error;
  }
};
