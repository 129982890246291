import React, { useCallback, useEffect, useState } from "react";
import Image from "../elements/Image";
import ProfilePhoto from "../../assets/noProfile.jpg";
import Input from "../form/Input";
import DatePickerInput from "../form/DatePickerInput";
import useForm from "../../hooks/useForm";
import { formValidate } from "../../helpers/formValidate";
import Button from "../form/Button";
import { addNewCardUser, editUser } from "../../services/HomeService";
import { toast } from "react-toastify";
import SingleImageUpload from "../form/SingleImageUpload";
import { getAdminList } from "../../services/adminService/UserService";
import SelectDropdownSearch from "../form/SelectDropdownSearch";
import { Validation } from "../../helpers/Validation";
import Accordion from "../extra/Accordion";
import { getPositionList } from "../../services/adminService/PositionService";

const UserEdit = ({ companyId, data, colorList, setEditModalOpen = () => {}, loadList = () => {} }) => {
  const [date, setDate] = useState({ startDate: null, endDate: null });
  const [selectedBackColor, setSelectedBackColor] = useState(null);
  const [selectedHeadingColor, setSelectedHeadingColor] = useState(null);
  const [selectedTextColor, setSelectedTextColor] = useState(null);
  const [headingTextColor, setHeadingTextColor] = useState(null);
  const [selectedImg, setSelectedImg] = useState("");
  const validation = {
    name: { required: true, message: "Please enter employee name" },
    email: { required: true, message: "Please enter employee email" },

    reportTo: { required: data?.parentId ? true : false, message: "Please select a employee" },
  };
  const [keyword, setKeyword] = useState("");
  const [loaded, setLoaded] = useState(false);
  const [list, setList] = useState([]);
  const [positionKeyword, setPositionKeyword] = useState("");
  const [positionList, setPositionList] = useState([]);
  const { values, handleChange, handleSubmit, errors, setFieldsValue } = useForm({}, validation);

  useEffect(() => {
    if (data?._id) {
      setFieldsValue({
        name: data?.userDetail?.name,
        email: data?.userDetail?.email,
        designation: data?.userDetail?.designation,
        phone: data?.userDetail?.phone,
        reportTo: data?.reportToDetail?.userId,
        positionId: data?.userDetail?.positionDetail?._id,
      });
      setDate({
        startDate: data?.userDetail?.dateOfBirth,
        endDate: data?.userDetail?.dateOfBirth,
      });
      setSelectedBackColor(data?.colorShade?.backgroundColor);
      setSelectedHeadingColor(data?.colorShade?.tabColor);
      setSelectedTextColor(data?.colorShade?.textColor);
      setHeadingTextColor(data?.colorShade?.headingColor);
      setSelectedImg(data?.userDetail?.image || "");
    }
  }, [data?._id]);

  const handleBackgroundColorClick = (hexCode) => {
    const clickedColor = colorList?.data?.find((color) => color?.hexCode === hexCode);
    if (clickedColor) {
      setSelectedBackColor(clickedColor?.hexCode);
    }
  };
  const handleHeadingColorClick = (hexCode) => {
    const clickedColor = colorList?.data?.find((color) => color?.hexCode === hexCode);
    if (clickedColor) {
      setSelectedHeadingColor(clickedColor?.hexCode);
    }
  };
  const handletextColorClick = (hexCode) => {
    const clickedColor = colorList?.data?.find((color) => color?.hexCode === hexCode);
    if (clickedColor) {
      setSelectedTextColor(clickedColor?.hexCode);
    }
  };
  const handleheadingtextColorClick = (hexCode) => {
    const clickedColor = colorList?.data?.find((color) => color?.hexCode === hexCode);
    if (clickedColor) {
      setHeadingTextColor(clickedColor?.hexCode);
    }
  };

  // loading the member list >>>>>>>>>
  const loadUserList = useCallback(() => {
    getAdminList({
      keyword: keyword,
      limit: 9999999999,
    }).then((res) => {
      if (res && res?.docs?.success) {
        setList(res?.docs?.data);
      }
    });
  }, [keyword]);

  useEffect(() => {
    loadUserList();
  }, [loadUserList]);
  const loadPositionList = useCallback(() => {
    getPositionList({
      keyword: positionKeyword,
      limit: 99999,
      status: "active",
      positionType: "global",
    }).then((res) => {
      if (res && res?.docs?.success) {
        setPositionList(res?.docs?.data);
      } else {
        toast.error(res?.error);
      }
    });
  }, [positionKeyword]);

  useEffect(() => {
    loadPositionList();
  }, [loadPositionList]);

  const onSubmit = async (values) => {
    setLoaded(true);
    try {
      if (!Validation("name", values.name)) {
        toast.error("Name cannot be empty or contain only spaces.");
        setLoaded(false);
        return;
      }
      if (!Validation("email", values.email)) {
        toast.error("Invalid email format. Please enter a valid email.");
        setLoaded(false);
        return;
      }
      if (!Validation("name", values.designation)) {
        toast.error("Title cannot be empty or contain only spaces.");
        setLoaded(false);
        return;
      }
      if (data?._id) {
        const res = await editUser({
          email: values?.email,
          name: values?.name,
          designation: "new",
          phone: values?.phone,
          relationId: data?._id,
          companyId: companyId,
          image: selectedImg ? selectedImg : null,
          dateOfBirth: date?.startDate,
          reportTo: values?.reportTo,
          positionId: values?.positionId,
          colorShade: {
            headingColor: headingTextColor,
            textColor: selectedTextColor,
            tabColor: selectedHeadingColor,
            backgroundColor: selectedBackColor,
          },
        });

        if (res?.success) {
          if (res?.status === 200 || res?.status === 201) {
            toast.success(res?.message);
            setEditModalOpen(false);
            loadList();
          } else {
            toast.error(res?.message);
          }
        } else {
          toast.error(res?.data?.error);
        }
        setLoaded(false);
      } else {
        const res = await addNewCardUser({
          email: values?.email,
          name: values?.name,
          designation: values?.designation,
          phone: values?.phone,
          relationId: data?.cardId,
          companyId: companyId,
          image: selectedImg ? selectedImg : null,
          dateOfBirth: date?.startDate,
          reportTo: values?.reportTo,
          colorShade: {
            headingColor: headingTextColor,
            textColor: selectedTextColor,
            tabColor: selectedHeadingColor,
            backgroundColor: selectedBackColor,
          },
        });
        if (res?.success) {
          if (res?.status === 200 || res?.status === 201) {
            toast.success(res?.message);
            setEditModalOpen(false);
            loadList();
          } else {
            toast.info(res?.message);
          }
        } else {
          toast.error(res?.error);
        }
        setLoaded(false);
      }
    } catch (err) {
      setLoaded(false);
      toast.error("Somthing went wrong !!!");
    }
  };

  return (
    <>
      <div className="w-full relative">
        <form onSubmit={(event) => handleSubmit(event, onSubmit)}>
          <div className="grid grid-cols-4 gap-4 divide-x divide-slate-200">
            <div className="col-span-3">
              <div className="grid grid-cols-12 gap-2">
                <div className="relative col-span-6">
                  <Input
                    label={"User Name"}
                    inputType={"name"}
                    inputPlaceholder={"Enter User Name"}
                    inputName={"name"}
                    value={values?.name}
                    onChange={handleChange}
                    {...formValidate(errors, "name")}
                  />
                </div>

                <div className="relative col-span-6">
                  <Input
                    label={"Email"}
                    inputType={"email"}
                    inputPlaceholder={"Enter Email"}
                    inputName={"email"}
                    value={values?.email}
                    onChange={handleChange}
                    {...formValidate(errors, "email")}
                  />
                </div>

                <div className="relative col-span-6">
                  {/* <Input
                    label={"Title"}
                    inputType={"text"}
                    inputPlaceholder={"Enter Title"}
                    inputName={"designation"}
                    value={values?.designation}
                    onChange={handleChange}
                    {...formValidate(errors, "designation")}
                  /> */}

                  <div className="relative col-span-6">
                    <SelectDropdownSearch
                      label={"Title"}
                      placeholder={"Select a Title"}
                      xPlacement={"bottom"}
                      buttonArrowIcon={""}
                      selectName={"positionId"}
                      isSearch={true}
                      dropdownButtonClass="!px-3  !border-gray-200 !w-full"
                      onChange={handleChange}
                      onSearch={(key) => setPositionKeyword(key)}
                      value={values?.positionId}
                      dropdownData={positionList?.map((item) => ({
                        name: item?.title,
                        _id: item?._id,
                      }))}
                      {...formValidate(errors, "positionId")}
                    />
                  </div>
                </div>

                {data?.parentId || data?.cardId ? (
                  <>
                    <div className="relative col-span-6">
                      <SelectDropdownSearch
                        label={"Report To"}
                        placeholder={"Select a employee"}
                        xPlacement={"bottom"}
                        buttonArrowIcon={""}
                        selectName={"reportTo"}
                        isSearch={true}
                        onSearch={(key) => setKeyword(key)}
                        dropdownButtonClass="!px-3  !border-gray-200 !w-full"
                        onChange={handleChange}
                        value={values?.reportTo}
                        dropdownData={list?.map((item) => ({
                          name: item?.name,
                          _id: item?._id,
                        }))}
                        // dropdownData={list}
                        {...formValidate(errors, "reportTo")}
                      />
                    </div>
                  </>
                ) : null}

                <div className="relative col-span-6">
                  <DatePickerInput
                    label="Date Of Birth"
                    labelClasses={"text-slate-500 "}
                    asSingle={true}
                    useRange={false}
                    value={date}
                    onChange={(val) => setDate(val)}
                  />
                </div>
                <div className="relative col-span-6">
                  <Input
                    label={"Phone"}
                    inputType={"text"}
                    inputPlaceholder={"Enter Ph."}
                    inputName={"phone"}
                    value={values?.phone}
                    onChange={handleChange}
                    {...formValidate(errors, "phone")}
                  />
                </div>
              </div>
            </div>
            <div className="col-span-1 pl-4">
              <div className="relative">
                <SingleImageUpload
                  size={"md"}
                  image={selectedImg}
                  setImage={setSelectedImg}
                  label={"Image"}
                  accept={["jpg", "png", "jpeg"]}
                />
              </div>
            </div>
          </div>

          <div className="container mx-auto p-4">
            <Accordion
              title="Choose BackGround Color"
              colorList={colorList}
              selectedColor={selectedBackColor}
              colorClick={handleBackgroundColorClick}
            />
            <Accordion
              title="Choose Text Color"
              colorList={colorList}
              selectedColor={selectedTextColor}
              colorClick={handletextColorClick}
            />
            <Accordion
              title="Choose Heading Color"
              colorList={colorList}
              selectedColor={selectedHeadingColor}
              colorClick={handleHeadingColorClick}
            />
            <Accordion
              title="Choose Heading Text Color"
              colorList={colorList}
              selectedColor={headingTextColor}
              colorClick={handleheadingtextColorClick}
            />
          </div>

          <div className="pt-2">
            <div className="flex items-center justify-end space-x-3">
              <Button
                buttonHasLink={false}
                buttonType={"submit"}
                buttonIcon={"fa-light fa-floppy-disk"}
                buttonClasses={"text-white"}
                buttonIconPosition={"left"}
                buttonLabel={"Save"}
                isDisable={loaded ? true : false}
              />
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default UserEdit;
