import React, { useState } from "react";

const Accordion = ({ title, content, colorList, colorClick = () => {}, selectedColor }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="border-b border-gray-200">
      <button
        className="w-full py-2 flex justify-between items-center text-left duration-300 ease-in-out"
        onClick={toggleAccordion}
        data-collapse-target="animated-collapse-1"
        type="button"
      >
        <span className="text-md font-medium text-slate-600">{title}</span>
        <svg
          className={`w-4 h-4 transform transition-transform ${isOpen ? "rotate-180" : ""}`}
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
        </svg>
      </button>
      <div className={`overflow-hidden transition-all duration-300 ${isOpen ? "max-h-screen" : "max-h-0"}`}>
        <div className="relative pb-3 pt-2 px-1">
          <div className="flex flex-wrap gap-2">
            {colorList?.data?.map((color) => (
              <div
                key={color?._id}
                style={{ backgroundColor: color?.hexCode }}
                className={`h-6 w-6 cursor-pointer rounded-sm ${
                  selectedColor === color?.hexCode ? "ring-1 ring-offset-2 ring-slate-500" : ""
                }`}
                onClick={() => colorClick(color.hexCode)}
              ></div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Accordion;
