import React, { useCallback, useContext, useEffect, useState } from "react";
import * as lodash from "lodash";
import AddCard from "./AddCard";
import Card from "./Card";
import { SpaceContext } from "react-zoomable-ui";
import { Rnd } from "react-rnd";
import { addRelation, getUserList } from "../../services/HomeService";
import { toast } from "react-toastify";
import Xarrow, { useXarrow } from "react-xarrows";

const CardWrapper = ({
  setXPlacement = () => {},
  companyId,
  randomId = null,
  setMainId = () => {},
  style,
  positions,
  setPositions = () => {},
  setMainIdForZIndex = () => {},
  loadList = () => {},
  setTree = () => {},
  tree,
  details,
  xPlacement,
  userList,
  colorList,
  userKeyword,
  searchValue,
  setUserKeyword = () => {},
  cardWidth,
  setCardWidth = () => {},
  handleEdit = () => {},
  handleViewDetails = () => {},
  onDelete = () => {},
  setDeleteModal = () => {},
  treeIds,
  addNewEmployee = () => {},
  ref_attr,
}) => {
  const [loading, setLoading] = useState(false);
  const [selectedUserValue, setSelectedUserValue] = useState({});
  const [sameObjectStyle, setSameObjectStyle] = useState();
  const dropdownData = [
    {
      _id: 1,
      type: "styleOne",
      click: () => {
        onSubmit({ style: "styleOne" });
      },
    },
    {
      _id: 2,
      type: "styleTwo",
      click: () => {
        onSubmit({ style: "styleTwo" });
      },
    },
    {
      _id: 3,
      type: "styleThree",
      click: () => {
        onSubmit({ style: "styleThree" });
      },
    },
    {
      _id: 4,
      type: "styleFour",
      click: () => {
        onSubmit({ style: "styleFour" });
      },
    },
    {
      _id: 5,
      type: "styleFive",
      click: () => {
        onSubmit({ style: "styleFive" });
      },
    },
  ];

  const [cardDetails, setCardDetails] = useState({});

  const onClickFunctionForMakeZindex = (xPlacements = "bottom") => {
    setXPlacement(xPlacements);
    localStorage.setItem("itemIdForZIndex", randomId);
    setMainIdForZIndex(randomId);
    setCardDetails(details);
  };

  const handleDragStop = (e, d) => {
    setPositions({ x: d.x, y: d.y });
    setMainId(randomId);

    if (randomId) {
      setTree((prevTree) => {
        return prevTree.map((item) => {
          if (item._id === randomId) {
            return {
              ...item,
              coordinateArrayLargeDevice: {
                x_axis: d.x,
                y_axis: d.y,
              },
              zIndex: 9,
            };
          }
          return item;
        });
      });
      addRelation({
        id: randomId,
        companyId: companyId,
        "coordinateArrayLargeDevice[x_axis]": d.x,
        "coordinateArrayLargeDevice[y_axis]": d.y,
      }).then((res) => {
        console.log(res.status, "resss");
        if (res?.success) {
          loadList();
        } else if (res?.status === 400) {
          toast.error(res.data.message);
          loadList();
        } else {
          toast.error(res?.message);
          loadList();
        }
      });
    }
  };

  //Add user in a relation
  const onSubmit = async (values) => {
    setLoading(true);
    let positionData = {};
    switch (xPlacement) {
      case "left":
        positionData = {
          x: cardDetails?.coordinateArrayLargeDevice?.x_axis - 384,
          y: cardDetails?.coordinateArrayLargeDevice?.y_axis,
        };
        break;
      case "right":
        positionData = {
          x: cardDetails?.coordinateArrayLargeDevice?.x_axis + 384,
          y: cardDetails?.coordinateArrayLargeDevice?.y_axis,
        };
        break;
      case "top":
        positionData = {
          x: cardDetails?.coordinateArrayLargeDevice?.x_axis,
          y: cardDetails?.coordinateArrayLargeDevice?.y_axis - 214,
        };
        break;
      case "bottom":
        positionData = {
          x: cardDetails?.coordinateArrayLargeDevice?.x_axis,
          y: cardDetails?.coordinateArrayLargeDevice?.y_axis + 214,
        };
        break;
      default:
        // Handle other positions if needed
        break;
    }

    try {
      const res = await addRelation({
        "coordinateArrayLargeDevice[x_axis]": positionData?.x,
        "coordinateArrayLargeDevice[y_axis]": positionData?.y,
        style: values?.style,
        parentId: randomId,
        companyId: companyId,
      });

      if (res?.success) {
        toast.success("Relation Add Successful");
        loadList();
      } else {
        throw new Error(res?.message);
      }
    } catch (error) {
      // console.log(error, "errrorr");
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (randomId) {
      setSameObjectStyle(details?.style);
    }
  }, [randomId]);

  //Add Same Object
  const onSameobjectSubmit = async (values) => {
    setLoading(true);
    let positionData = {};
    switch (xPlacement) {
      case "left":
        positionData = {
          x: cardDetails?.coordinateArrayLargeDevice?.x_axis - 384,
          y: cardDetails?.coordinateArrayLargeDevice?.y_axis,
        };
        break;
      case "right":
        positionData = {
          x: cardDetails?.coordinateArrayLargeDevice?.x_axis + 384,
          y: cardDetails?.coordinateArrayLargeDevice?.y_axis,
        };
        break;
      case "top":
        positionData = {
          x: cardDetails?.coordinateArrayLargeDevice?.x_axis,
          y: cardDetails?.coordinateArrayLargeDevice?.y_axis - 214,
        };
        break;
      case "bottom":
        positionData = {
          x: cardDetails?.coordinateArrayLargeDevice?.x_axis,
          y: cardDetails?.coordinateArrayLargeDevice?.y_axis + 214,
        };
        break;
      default:
        // Handle other positions if needed
        break;
    }

    try {
      const res = await addRelation({
        "coordinateArrayLargeDevice[x_axis]": positionData?.x,
        "coordinateArrayLargeDevice[y_axis]": positionData?.y,
        style: sameObjectStyle,
        parentId: randomId,
        companyId: companyId,
      });

      if (res?.success) {
        toast.success("Relation Add Successful");
        loadList();
      } else {
        throw new Error(res?.message);
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (details?._id) {
      setSelectedUserValue(details?.userDetail?._id);
    }
  }, [details]);

  //Update Relation Onchange
  const handleChange = async (value) => {
    setSelectedUserValue(value);
    try {
      if (value) {
        const res = await addRelation({
          id: randomId,
          userId: value,
          companyId: companyId,
        });
        if (res?.success) {
          toast.success("Data Update successful");
          loadList();
        } else if (res?.data?.success) {
          toast.info(res?.data?.message);
        } else {
          toast.error(res?.message);
        }
      }
    } catch (error) {
      toast.error("An error occurred while updating data");
    }
  };
  const updateXarrow = useXarrow();

  let search = searchValue;

  // console.log(ref_attr,'ref_attr')

  return (
    <>
      <Rnd
        position={positions}
        onDragStop={handleDragStop}
        onDrag={updateXarrow}
      >
        <div
          className={`relative group/card ${
            searchValue &&
            details?.userDetail?.name
              ?.replace(/\s+/g, "")
              ?.toLowerCase()
              .includes(searchValue?.replace(/\s+/g, "")?.toLowerCase())
              ? ""
              : searchValue && "!blur-sm"
          }`}
          onClick={onClickFunctionForMakeZindex}
          id={randomId}
          {...ref_attr}
        >
          <Card
            styleType={style}
            randomId={randomId}
            details={details}
            userList={userList}
            colorList={colorList}
            userKeyword={userKeyword}
            setUserKeyword={setUserKeyword}
            handleChange={handleChange}
            selectedUserValue={selectedUserValue}
            setSelectedUserValue={setSelectedUserValue}
            handleViewDetails={handleViewDetails}
            handleEdit={handleEdit}
            setDeleteModal={setDeleteModal}
            treeIds={treeIds}
            addNewEmployee={addNewEmployee}
          />
          {/* <div className="absolute -top-8 left-1/2 -translate-x-1/2">
            <AddCard
              xPlacement="top"
              dropdownData={dropdownData}
              dropdownButtonClasses={
                "opacity-0 invisible group-hover/card:opacity-100 group-hover/card:visible"
              }
              onClickFunction={onClickFunctionForMakeZindex}
              onSameobjectSubmit={onSameobjectSubmit}
            />
          </div> */}
          <div className="absolute -bottom-8 left-1/2 -translate-x-1/2">
            <AddCard
              xPlacement="bottom"
              dropdownData={dropdownData}
              dropdownButtonClasses={
                "opacity-0 invisible group-hover/card:opacity-100 group-hover/card:visible"
              }
              onClickFunction={onClickFunctionForMakeZindex}
              onSameobjectSubmit={onSameobjectSubmit}
            />
          </div>
          <div className="absolute -left-8 top-1/2 -translate-y-1/2">
            <AddCard
              xPlacement="left"
              dropdownData={dropdownData}
              dropdownButtonClasses={
                "opacity-0 invisible group-hover/card:opacity-100 group-hover/card:visible"
              }
              onClickFunction={onClickFunctionForMakeZindex}
              onSameobjectSubmit={onSameobjectSubmit}
            />
          </div>
          <div className="absolute -right-8 top-1/2 -translate-y-1/2">
            <AddCard
              xPlacement="right"
              dropdownData={dropdownData}
              dropdownButtonClasses={
                "opacity-0 invisible group-hover/card:opacity-100 group-hover/card:visible"
              }
              onClickFunction={onClickFunctionForMakeZindex}
              onSameobjectSubmit={onSameobjectSubmit}
            />
          </div>
        </div>
      </Rnd>
    </>
  );
};

export default CardWrapper;
