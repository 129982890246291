import React, { useCallback, useEffect, useState } from "react";
import Button from "../components/form/Button";
import UserDropdown from "./UserDropdown";
import Modal from "../components/modal/Modal";
import LogoutModal from "../components/elements/logoutModal/LogoutModal";
import { Link, useNavigate } from "react-router-dom";
import Logo from "../assets/mylogo.png";
import { superAdminDirectLogin } from "../services/LoginService";
import { toast } from "react-toastify";
import Input from "../components/form/Input";
import SelectDropdownSearch from "../components/form/SelectDropdownSearch";
import { useDispatch } from "react-redux";
import { getSearch } from "../store/chartSearch/searchSlice";
import { getAdminList } from "../services/adminService/UserService";
import { useSelector } from "react-redux";
import { getEmployeeSearch } from "../store/employeeSearch/employeeSlice";

const DefaultHeader = ({
  isMenuOpen,
  setIsMenuOpen,
  userData,
  isToggleIcon,
  siteData,
  settings,
  isSearch,
}) => {
  const [isOpen, setOpen] = useState(false);
  const [adminData, setAdminData] = useState({});
  const [loading, setLoading] = useState(true);
  const [keyword, setKeyword] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [key, setKey] = useState("");
  const [list, setList] = useState([]);
  const [employeeValue, setEmployeeValue] = useState();
  const profile = useSelector((state) => state.profile.profileData);
  const companyData =
    profile?.companyDetail &&
    profile?.companyDetail.length > 0 &&
    profile?.companyDetail[0];

  const menuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  const dropdownMenuAdmin = [
    // {
    //   _id: 1,
    //   label: "My Profile",
    //   link: "/myProfile",
    //   icon: "fa-solid fa-user",
    // },
    {
      _id: 2,
      label: "Log Out",
      icon: "fa-regular fa-fw fa-arrow-right-from-arc",
      onclick: (val) => {
        setOpen(true);
      },
    },
  ];
  const dropdownMenuFront = [
    {
      _id: 1,
      label: "My Profile",
      link: "/myProfile",
      icon: "fa-solid fa-user",
    },
    // {
    //   _id: 2,
    //   label: "Employees",
    //   link: "/people",
    //   icon: "fa-regular fa-users",
    // },
    {
      _id: 2,
      label: "Log Out",
      icon: "fa-regular fa-fw fa-arrow-right-from-arc",
      onclick: (val) => {
        setOpen(true);
      },
    },
  ];

  useEffect(() => {
    const dataFromLocalStorage = localStorage.getItem("adminData");
    if (dataFromLocalStorage) {
      const parsedData = JSON.parse(dataFromLocalStorage);
      setAdminData(parsedData);
    }
  }, []);

  //Direct login Api call Back to super admin
  const onSubmit = async (values) => {
    if (values?._id && values?.uniqueId) {
      localStorage.setItem("auth_token", values?.adminToken);
      localStorage.setItem("refreshToken", values.adminRefreshToken);
      localStorage.setItem("role", "superadmin");
      navigate("/admin/administrator");
    }
  };
  const loadList = useCallback(() => {
    if (companyData?._id) {
      getAdminList({
        keyword: key,
        limit: 10,
        companyId: companyData?._id,
      }).then((res) => {
        if (res && res?.docs?.success) {
          setList(res?.docs?.data);
        }
      });
    }
  }, [key, companyData?._id]);

  useEffect(() => {
    loadList();
  }, [loadList]);

  return (
    <>
      {siteData === "FrontPannel" && (
        <>
          <header className="sticky top-0 py-1 z-50 w-full flex items-center px-6 bg-transparent  gap-4">
            <div className="relative flex gap-2">
              <Button
                buttonHasLink={false}
                buttonIcon={
                  !isMenuOpen
                    ? "fa-duotone fa-backward"
                    : "fa-duotone fa-forward"
                }
                buttonIconPosition={"left"}
                buttonType={"button"}
                buttonClasses={
                  "!text-victoria !bg-transparent border-transparent hover:!bg-org-theme hover:!text-org-silver !text-2xl"
                }
                buttonFunction={menuToggle}
              />
            </div>
            <div className="relative ml-auto flex justify-end items-center gap-3">
              {/* <div className="relative px-1 border-x border-slate-200">
            <NotificationDropdown xPlacement={"right"} />
          </div> */}
              {isSearch && (
                <>
                  {/* <div className="w-80">
                    <SelectDropdownSearch 
                      label={""}
                      placeholder={"Select Employee"}
                      xPlacement={"bottom"}
                      buttonArrowIcon={""}
                      isSearch={true}
                      onSearch={(key) => setKey(key)}
                      dropdownButtonClass="!px-3  !border-gray-200 !w-full"
                      onChange={(value) => {
                        setEmployeeValue(value?.target?.value);
                        dispatch(getEmployeeSearch(value?.target?.value));
                      }}
                      value={employeeValue}
                      dropdownData={list?.map((item) => ({
                        name: item?.name,
                        _id: item?._id,
                      }))}
                    />
                  </div> */}
                  <div className="w-80">
                    <Input
                      inputType={"text"}
                      isInputGroup={true}
                      inputGroupPosition={"left"}
                      inputGroupIcon={"fa-regular fa-search"}
                      inputPlaceholder={"Search"}
                      inputClasses={"!pl-3"}
                      value={keyword}
                      onChange={(e) => {
                        setKeyword(e.target.value);
                        dispatch(getSearch(e.target.value));
                      }}
                    />
                  </div>
                </>
              )}

              <div className="relative  px-2 py-3">
                <UserDropdown
                  xPlacement={"right"}
                  data={
                    siteData === "AdminPannel"
                      ? dropdownMenuAdmin
                      : dropdownMenuFront
                  }
                  userData={userData}
                />
              </div>
            </div>
          </header>
        </>
      )}

      {siteData === "AdminPannel" && (
        <>
          <header className="sticky top-0 py-1 z-50 w-full flex items-center px-6 bg-white shadow gap-4">
            <div className="relative flex gap-2">
              <Button
                buttonType={"button"}
                buttonIcon={"fa-solid fa-bars"}
                buttonIconPosition={"left"}
                buttonClasses={
                  "px-0 w-10 !bg-slate-200 !text-slate-600 hover:!bg-slate-300 hover:!text-slate-900"
                }
                buttonFunction={menuToggle}
              />
            </div>
            <div className="relative ml-auto flex justify-end items-center gap-3">
              {/* <div className="relative px-1 border-x border-slate-200">
            <NotificationDropdown xPlacement={"right"} />
          </div> */}
              <div className="relative  px-2 py-3">
                <UserDropdown
                  xPlacement={"right"}
                  data={
                    siteData === "AdminPannel"
                      ? dropdownMenuAdmin
                      : dropdownMenuFront
                  }
                  userData={userData}
                />
              </div>
            </div>
          </header>
        </>
      )}
      <LogoutModal isOpen={isOpen} setOpen={setOpen} />
    </>
  );
};

export default DefaultHeader;
