import { apiRequest } from "../util/async/apiUtils";

export const addCsv = async (params) => {
    try {
      const res = await apiRequest(
        `${"post"}`,
        `${"user/csv-upload"}`,
        {
          body: {
            ...params,
          },
        },
        "multipart/form-data"
      );
      const data = res.data;
      return res;
    } catch (err) {
      return err;
    }
  };