import React, { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import useForm from "../../../hooks/useForm";
import { addEmployee, employeeDetails, getAdminList, getCompanyList } from "../../../services/adminService/UserService";
import { Validation } from "../../../helpers/Validation";
import PageHeader from "../../../shared/PageHeader";
import Button from "../../../components/form/Button";
import Input from "../../../components/form/Input";
import DatePickerInput from "../../../components/form/DatePickerInput";
import SingleImageUpload from "../../../components/form/SingleImageUpload";
import { formValidate } from "../../../helpers/formValidate";
import SelectDropdownSearch from "../../../components/form/SelectDropdownSearch";
import { useSelector } from "react-redux";
import { getPositionList } from "../../../services/adminService/PositionService";

const AddEditPeople = ({
  setEditModalOpen = () => {},
  editModalOpen,
  setUserDetails = () => {},
  userDetails,
  setCount = () => {},
}) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const validation = {
    name: { required: true, message: "Please enter employee name" },
    email: { required: true, message: "Please enter employee email" },
    reportTo: { required: true, message: "Please select a employee" },
  };
  const { values, handleChange, handleSubmit, errors, setFieldsValue } = useForm({}, validation);
  const [imageUrl, setImageUrl] = useState("");
  const [resData, setResData] = useState({});
  const [loaded, setLoaded] = useState(false);
  const [date, setDate] = useState();
  const [detailsData, setDetailsData] = useState({ loading: false, data: {} });
  const [selectedUserValue, setSelectedUserValue] = useState({});
  const [limit, setLimit] = useState({ name: "10 Items", value: 10 });
  const [status, setStatus] = useState("all");
  const [featured, setFeatured] = useState(" ");
  const [keyword, setKeyword] = useState("");
  const [list, setList] = useState([]);
  const [positionKeyword, setPositionKeyword] = useState("");
  const [positionList, setPositionList] = useState([]);
  // const [count, setCount] = useState(0);
  const [selectedRoleValue, setSelectedRoleValue] = useState({
    name: "Employee",
    value: "employee",
  });
  const profile = useSelector((state) => state.profile.profileData);

  const companyData = profile?.companyDetail && profile?.companyDetail.length > 0 && profile?.companyDetail[0];

  const getDetails = useCallback(() => {
    if (userDetails?._id) {
      setDetailsData({ loading: true, data: {} });
      employeeDetails({ id: userDetails?._id, roleCode: "employee" }).then((res) => {
        // console.log(res, "res");
        if (res?.status === 200) {
          setDetailsData({ loading: true, data: res?.docs });
          const companyData =
            res?.docs?.companyDetail && res?.docs?.companyDetail.length > 0 && res?.docs?.companyDetail[0];
          setFieldsValue({
            name: res?.docs?.name || "",
            email: res?.docs?.email || "",
            phone: res?.docs?.phone || "",
            designation: res?.docs?.designation || "",
            image: res?.docs?.image?.url,
            // company: companyData?._id,
            role: res?.docs?.roleCode,
            employeeId: res?.docs?.employeeId,
            reportTo: res?.docs?.reportToField?._id,
            positionId: res?.docs?.positionDetail?._id,
          });
          setImageUrl(res?.docs?.image?.url);
          setDate({
            startDate: res?.docs?.dateOfBirth,
            endDate: res?.docs?.dateOfBirth,
          });
        }
      });
    }
  }, [userDetails?._id]);

  // const roleData = [
  //   { name: "Employee", value: "employee" },
  //   // { name: "Administrator", value: "admin" },
  // ];

  // console.log(values, "setdetails");

  useEffect(() => {
    getDetails();
  }, [getDetails]);

  const loadPositionList = useCallback(() => {
    getPositionList({
      keyword: positionKeyword,
      limit: 99999,
      status: "active",
      positionType: "global",
    }).then((res) => {
      if (res && res?.docs?.success) {
        setPositionList(res?.docs?.data);
      } else {
        toast.error(res?.message);
      }
    });
  }, [positionKeyword]);

  useEffect(() => {
    loadPositionList();
  }, [loadPositionList]);

  // loading the member list >>>>>>>>>
  const loadList = useCallback(() => {
    getAdminList({
      keyword: keyword,
      limit: 9999999999,
    }).then((res) => {
      if (res && res?.docs?.success) {
        setList(res?.docs?.data);
        // setCount(res?.docs?.metadata?.totalDocs);
      }
      // else {
      //   toast.error(res?.message);
      // }
    });
  }, [limit, keyword]);

  useEffect(() => {
    loadList();
  }, [loadList]);

  const handleDateChange = (value) => {
    setDate(value?.startDate);
  };

  const onSubmit = async (values) => {
    setLoaded(true);
    try {
      const res = await addEmployee({
        id: userDetails?._id,
        employeeId: values?.employeeId,
        image: imageUrl,
        name: values?.name,
        email: values?.email,
        phone: values?.phone,
        designation: "new",
        dateOfBirth: date?.startDate,
        roleCode: "employee",
        companyId: companyData?._id,
        reportTo: values?.reportTo,
        positionId: values?.positionId,
      });
      if (res?.data?.success) {
        toast.info(res?.data?.message);
        if (res?.data?.status === 200 || res?.data?.status === 201) {
          // navigate("/admin/employees");
          setCount((pre) => pre + 1);
          setEditModalOpen(false);
          setUserDetails({});
        }
      } else {
        toast.error(res?.data?.error);
      }
      setLoaded(false);
    } catch (err) {
      setLoaded(false);
      toast.error("Somthing went wrong !!!");
    }
  };

  return (
    <div>
      <form onSubmit={(event) => handleSubmit(event, onSubmit)}>
        <div className="py-2 px-6">
          <div className="w-full bg-white rounded-md shadow  py-5 px-4">
            <div className="grid md:grid-cols-4 grid-cols-12 gap-4 divide-x divide-slate-200">
              <div className="md:col-span-3 col-span-12">
                <div className="space-y-3">
                  <div className="grid grid-cols-12 gap-4">
                    <div className="relative md:col-span-6 col-span-12">
                      <Input
                        isInputGroup={true}
                        inputGroupIcon={"fa-solid fa-id-card"}
                        inputGroupPosition={"left"}
                        label={"Employee Id"}
                        inputPlaceholder={"Enter Employee Id"}
                        labelClasses={"!text-base"}
                        inputType={"text"}
                        value={values.employeeId}
                        inputName={"employeeId"}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="relative md:col-span-6 col-span-12">
                      <Input
                        isInputGroup={true}
                        inputGroupIcon={"fa-regular fa-user"}
                        inputGroupPosition={"left"}
                        label={"Name"}
                        inputPlaceholder={"Enter Employee Name"}
                        labelClasses={"!text-base"}
                        inputType={"text"}
                        value={values.name}
                        inputName={"name"}
                        onChange={handleChange}
                        {...formValidate(errors, "name")}
                      />
                    </div>
                    <div className="relative md:col-span-6 col-span-12">
                      <Input
                        isInputGroup={true}
                        inputGroupIcon={"fa-solid fa-envelope"}
                        inputGroupPosition={"left"}
                        label={"Email"}
                        inputPlaceholder={"Enter Employee Email"}
                        labelClasses={"!text-base"}
                        inputType={"text"}
                        value={values.email}
                        inputName={"email"}
                        onChange={handleChange}
                        {...formValidate(errors, "email")}
                      />
                    </div>
                    {/* <div className="relative md:col-span-6 col-span-12">
                      <Input
                        isInputGroup={true}
                        inputGroupIcon={"fa-sharp fa-solid fa-clipboard-user"}
                        inputGroupPosition={"left"}
                        label={"Title"}
                        inputPlaceholder={"Enter Employee Title"}
                        labelClasses={"!text-base"}
                        inputType={"text"}
                        value={values.designation}
                        inputName={"designation"}
                        onChange={handleChange}
                        {...formValidate(errors, "designation")}
                      />
                    </div> */}
                    <div className="relative col-span-6">
                      <SelectDropdownSearch
                        label={"Title"}
                        placeholder={"Select a Title"}
                        xPlacement={"bottom"}
                        buttonArrowIcon={""}
                        selectName={"positionId"}
                        isSearch={true}
                        dropdownButtonClass="!px-3  !border-gray-200 !w-full"
                        onChange={handleChange}
                        onSearch={(key) => setPositionKeyword(key)}
                        value={values?.positionId}
                        dropdownData={positionList?.map((item) => ({
                          name: item?.title,
                          _id: item?._id,
                        }))}
                        {...formValidate(errors, "positionId")}
                      />
                    </div>
                    <div className="relative col-span-6">
                      <SelectDropdownSearch
                        label={"Report To"}
                        placeholder={"Select a employee"}
                        xPlacement={"bottom"}
                        buttonArrowIcon={""}
                        selectName={"reportTo"}
                        isSearch={true}
                        onSearch={(key) => setKeyword(key)}
                        dropdownButtonClass="!px-3  !border-gray-200 !w-full"
                        onChange={handleChange}
                        value={values?.reportTo}
                        dropdownData={list?.map((item) => ({
                          name: item?.name,
                          _id: item?._id,
                        }))}
                        // dropdownData={list}
                        {...formValidate(errors, "reportTo")}
                      />
                    </div>
                    <div className="relative md:col-span-6 col-span-12">
                      <Input
                        isInputGroup={true}
                        inputGroupIcon={"fa-solid fa-phone"}
                        inputGroupPosition={"left"}
                        label={"Phone No"}
                        inputPlaceholder={"Enter Employee Phone No"}
                        labelClasses={"!text-base"}
                        inputType={"number"}
                        value={values.phone}
                        inputName={"phone"}
                        onChange={handleChange}
                        {...formValidate(errors, "phone")}
                      />
                    </div>

                    <div className="relative md:col-span-6 col-span-12">
                      <DatePickerInput
                        label="Date Of Birth"
                        asSingle={true}
                        useRange={false}
                        value={date}
                        onChange={(val) => setDate(val)}
                        showShortcuts={false}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-span-1 pl-4">
                <div className="relative">
                  <SingleImageUpload
                    label={"Employee Image"}
                    size={"md"}
                    image={imageUrl}
                    setImage={setImageUrl}
                    accept={["jpg", "png", "jpeg"]}
                  />
                </div>
              </div>
            </div>
            <div className="mt-8 pt-4 border-t border-slate-200">
              <div className="flex items-center space-x-3">
                <Button
                  buttonHasLink={false}
                  buttonType={"button"}
                  buttonIcon={"fa-regular fa-xmark"}
                  buttonIconPosition={"left"}
                  buttonClasses={"!bg-org-silver !border-org-theme !text-org-theme"}
                  buttonLabel={"Cancel"}
                  buttonLabelClasses={"font-medium !text-sm"}
                  buttonFunction={() => {
                    setEditModalOpen(false);
                    setUserDetails({});
                  }}
                />
                <Button
                  buttonHasLink={false}
                  buttonType={"submit"}
                  buttonIcon={"fa-light fa-floppy-disk"}
                  buttonIconPosition={"left"}
                  buttonLabel={"Save"}
                  isDisable={loaded ? true : false}
                />
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default AddEditPeople;
