import React, { useState, useRef, useEffect, Fragment, useMemo } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { usePopper } from "react-popper";
import { bottom, top, left, right } from "@popperjs/core";
import { classNames } from "../../helpers/classNames";
import Search from "../form/Search";
import Button from "../form/Button";

const UserDropdown = ({
  xPlacement,
  dropdownData = [],
  notSelectedItem,
  dropdownClass,
  dropdownButtonClass,
  dropdownButtonStyle, // Add this prop for custom styles
  selectedValue,
  label,
  labelClasses,
  onSearch = () => {},
  onChange = () => {},
  isSearch,
  buttonArrowIconClasses,
  buttonArrowIcon = "fa-regular fa-chevron-down",
  tabTextColor,
  setEmployeeModalOpen = () => {},
  addNewEmployee = () => {},
  details,
  ...props
}) => {
  const [selected, setSelected] = useState(
    selectedValue ? selectedValue : dropdownData[0]
  );
  const popperElRef = useRef(null);
  const [targetElement, setTargetElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);

  const placements = {
    bottom: bottom,
    bottomLeft: "bottom-start",
    bottomRight: "bottom-end",
    top: top,
    topLeft: "top-start",
    topRight: "top-end",
    right: right,
    left: left,
    "": "",
  };

  const { styles, attributes } = usePopper(targetElement, popperElement, {
    placement: placements[xPlacement],
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [0, 2],
        },
      },
    ],
  });

  // useEffect(() => {
  //   if (selectedValue) {
  //     const index = dropdownData.findIndex(
  //       (item) => item._id === selectedValue || item.value === selectedValue
  //     );
  //     if (index !== -1) {
  //       setSelected(dropdownData[index]);
  //     }
  //   }
  // }, [dropdownData, selectedValue]);

  useEffect(() => {
    if (selectedValue) {
      const index = dropdownData.findIndex(
        (item) => item._id === selectedValue || item.value === selectedValue
      );
      if (index !== -1) {
        setSelected(dropdownData[index]);
      }
    }
  }, [dropdownData, selectedValue]);

  const onSearchHandler = (e) => {
    onSearch(e);
  };

  const handleChange = (e) => {
    if (!notSelectedItem.includes(e?._id)) {
      onChange(e?._id || e?.value);
      setSelected(e);
    }
  };
  return (
    <>
      <div className="relative space-y-1 w-full">
        {label && (
          <div
            className={classNames(
              "text-sm font-semibold text-opplio-gray tracking-none",
              labelClasses
            )}
          >
            {label}
          </div>
        )}
        <Listbox
          value={selected}
          onChange={(e) => handleChange(e)}
          as={"div"}
          className="relative"
        >
          {({ open }) => (
            <>
              <Listbox.Button
                ref={setTargetElement}
                className="flex items-center w-full"
              >
                <div
                  className={classNames(
                    "relative w-full h-10 flex items-center justify-between focus:ring-0 transition-all duration-200 gap-1 pl-3 pr-6 rounded-md border text-sm font-medium",
                    dropdownButtonClass
                  )}
                  style={dropdownButtonStyle} // Apply custom styles here
                >
                  <div
                    className={classNames(
                      "whitespace-nowrap truncate",
                      tabTextColor ? "" : "text-white"
                    )}
                    style={tabTextColor ? { color: tabTextColor } : {}}
                  >
                    {selected?.name ||"Select User"}
                  </div>
                  {buttonArrowIcon && (
                    <div
                      className={classNames(
                        "absolute top-1/2 right-1 -translate-y-1/2 transition-all duration-200 text-xs",
                        open ? "-rotate-180" : "",
                        buttonArrowIconClasses,
                        tabTextColor ? "" : "text-white"
                      )}
                      style={tabTextColor ? { color: tabTextColor } : {}}
                    >
                      <i className={classNames("fa-fw", buttonArrowIcon)}></i>
                    </div>
                  )}
                </div>
              </Listbox.Button>
              <Transition
                as={Fragment}
                className="z-50"
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
                beforeEnter={() => setPopperElement(popperElRef.current)}
                afterLeave={() => setPopperElement(null)}
                ref={popperElRef}
                style={styles.popper}
                {...attributes.popper}
              >
                <Listbox.Options
                  className={classNames(
                    "absolute mt-1 w-full origin-top-right rounded-md bg-white shadow-lg focus:outline-none py-1",
                    dropdownClass
                  )}
                >
                  {isSearch && (
                    <div className="relative flex">
                      <div className="py-1 px-2 border-b border-slate-100">
                        <Search
                          placeholder={"Search"}
                          onSearch={(e) => onSearchHandler(e)}
                        />
                      </div>
                      <div className="flex justify-center items-center h-full py-1 px-2">
                        <Button
                          buttonHasLink={false}
                          buttonLabel={"Add"}
                          buttonIcon={"fa-solid fa-plus"}
                          buttonIconPosition={"left"}
                          buttonType={"button"}
                          buttonClasses={
                            "!text-victoria justifyContent-center !bg-transparent border-org-theme hover:!bg-org-theme hover:!text-org-silver !text-2xl"
                          }
                          buttonFunction={() => addNewEmployee(details)}
                        />
                      </div>
                    </div>
                  )}
                  {/* {dropdownData && dropdownData.length === 0 && (
                    <>
                      <div className="flex justify-center items-center h-full">
                        <Button
                          buttonHasLink={false}
                          buttonLabel={"Add New Eployee"}
                          buttonIcon={"fa-solid fa-plus"}
                          buttonIconPosition={"left"}
                          buttonType={"button"}
                          buttonClasses={
                            "!text-victoria justifyContent-center !bg-transparent border-org-theme hover:!bg-org-theme hover:!text-org-silver !text-2xl"
                          }
                          // buttonFunction={menuToggle}
                        />
                      </div>
                    </>
                  )} */}
                  <div className="py-1 max-h-80 overflow-auto scrollbar">
                    {dropdownData?.map((item, index) => (
                      <Listbox.Option
                        key={index}
                        className={({ active }) =>
                          classNames(
                            "relative group flex w-full items-center px-3 py-1.5 pl-8 text-sm gap-2 transition-all duration-200 cursor-pointer",
                            active
                              ? "bg-slate-200 text-org-theme"
                              : selectedValue?._id == item?._id
                              ? "! bg-green-300 text-slate-800"
                              : "text-org-theme",
                            item.isDisabled
                              ? "!bg-slate-100 !cursor-not-allowed"
                              : "",
                            item.class,
                            notSelectedItem.includes(item?._id)
                              ? "!text-slate-800 !cursor-not-allowed "
                              : ""
                          )
                        }
                        value={item}
                        disabled={item.isDisabled}
                      >
                        {({ selected }) => (
                          <>
                            {item.icon && (
                              <i className={classNames("fa-fw", item.icon)}></i>
                            )}
                            <div>{item.name + `(${item?.email})`}</div>
                            {selected && (
                              <div className="absolute inset-y-0 left-0 flex items-center pl-2 text-green-500 text-base">
                                <i className="fa-regular fa-fw fa-check"></i>
                              </div>
                            )}
                          </>
                        )}
                      </Listbox.Option>
                    ))}
                  </div>
                </Listbox.Options>
              </Transition>
            </>
          )}
        </Listbox>
      </div>
    </>
  );
};

export default UserDropdown;
