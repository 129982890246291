import React, { useEffect, useState } from "react";
import siteLogo from "../assets/mylogo.png";
import Input from "../components/form/Input";
import Button from "../components/form/Button";
import useForm from "../hooks/useForm";
import { formValidate } from "../helpers/formValidate";
import { toast } from "react-toastify";
import { SignInService, viewLoginRelation } from "../services/LoginService";
import { Link, useNavigate } from "react-router-dom";
import CryptoJS from "crypto-js";
import { uniqueId } from "lodash";

const ViewRelation = () => {
  const backgroundStyle = {
    backgroundImage: "url(/signinBackground.jpeg)",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  };
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState({
    color: "red-600",
    message: null,
  });
  const [loaded, setLoaded] = useState(true);
  const [decryptedData, setDecryptedData] = useState({});

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const encryptedData = urlParams.get("data");
    if (encryptedData) {
      try {
        const bytes = CryptoJS.AES.decrypt(encryptedData, "your-secret-key");
        const decryptedDataString = bytes.toString(CryptoJS.enc.Utf8);
        const decryptedDataObj = JSON.parse(decryptedDataString);
        setDecryptedData(decryptedDataObj);
      } catch (error) {
        console.error("Error decrypting data:", error);
      }
    }
  }, []);
  //   console.log(decryptedData, "decryptedDatadecryptedData");

  useEffect(() => {
    if (Object.keys(decryptedData).length !== 0) {
      localStorage.setItem("auth_token", decryptedData?.viewTokenAccess);
      localStorage.setItem("refreshToken", decryptedData?.viewTokenRefresh);
      navigate("/");
    }
  }, [decryptedData]);

  const onChangePage = () => {
    navigate("/signin");
  };
  return (
    <div
      className="w-full min-h-screen flex items-center justify-center py-5 px-5"
      style={backgroundStyle}
    >
      <div className="w-full max-w-md xl:max-w-lg bg-white py-10 px-4 sm:px-10 rounded-3xl">
        <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
          <div
            className="sm:mx-auto sm:w-full sm:max-w-sm cursor-pointer"
            onClick={onChangePage}
          >
            <img
              className="mx-auto h-24 w-auto"
              src={siteLogo}
              alt="Your Company"
            />
            <h2
              className="mt-1 text-center text-xl font-bold leading-9 tracking-tight text-slate-600 cursor-pointer"
              onClick={onChangePage}
            >
              Sign In
            </h2>
            <h4
              className={`mt-1 text-center text-xl font-bold leading-9 tracking-tight text-${errorMessage?.color}`}
            >
              {errorMessage?.message}
            </h4>
          </div>

          {/* <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
            <form onSubmit={(event) => handleSubmit(event, onSubmit)}>
              <div>
                <label
                  htmlFor="email"
                  className="block text-md font-medium leading-6 text-slate-600"
                >
                  Email
                </label>
                <div className="mt-2">
                  <Input
                    label={""}
                    labelClasses={"!text-xs"}
                    inputType={"text"}
                    inputPlaceholder={"Enter your email"}
                    value={values.email}
                    inputName={"email"}
                    onChange={handleChange}
                    {...formValidate(errors, "email")}
                  />
                </div>
              </div>

              <div>
                <div className="flex items-center justify-between">
                  <label
                    htmlFor="password"
                    className="block text-md font-medium leading-6 text-slate-600"
                  >
                    Password
                  </label>
                  <div className="text-sm">
                    <Link
                      to="/forgotPassword"
                      className="font-semibold text-victoria hover:text-primary-two"
                    >
                      Forgot password?
                    </Link>
                  </div>
                </div>
                <div className="mt-2">
                  <Input
                    label={""}
                    labelClasses={"!text-xs"}
                    inputType={"password"}
                    inputPlaceholder={"Enter your password"}
                    value={values.password}
                    inputName={"password"}
                    onChange={handleChange}
                    {...formValidate(errors, "password")}
                  />
                </div>
              </div>

              <div className="mt-2">
                <Button
                  buttonClasses={
                    "w-full text-white  hover:bg-primary-two focus-visible:outline  justify-center rounded-md"
                  }
                  buttonHasLink={false}
                  buttonType={"submit"}
                  buttonLabel={" Sign in"}
                />
              </div>
            </form>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default ViewRelation;
