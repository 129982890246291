import React, { useState } from "react";
import Input from "../components/form/Input";
import { useNavigate } from "react-router-dom";
import useForm from "../hooks/useForm";
import { formValidate } from "../helpers/formValidate";
import Button from "../components/form/Button";
import { resetPassword } from "../services/ProfileService";
import { toast } from "react-toastify";

const ChangePassword = ({ email, verifiedOtpData }) => {
  const navigate = useNavigate();
  const [loaded, setLoaded] = useState(true);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const validation = {
    newPassword: { required: true, message: "Please Input Your New Password!" },
    confirmPassword: {
      required: password ? true : false,
      message: "Please Input Your Confirm Password!",
    },
  };

  const { values, handleChange, handleSubmit, errors, setFieldsValue } = useForm({}, validation);

  const onSubmit = async (values) => {
    setLoaded(false);
    try {
      await resetPassword({
        otp: verifiedOtpData,
        email: email,
        newPassword: values?.newPassword,
        confirmPassword: values?.confirmPassword,
      }).then((res) => {
        if (res?.status === 200) {
          toast.success(res?.data?.message);
          navigate(`/signin`);
        } else {
          toast.error(res?.data?.message);
        }
      });
      setLoaded(true);
    } catch (error) {
      // console.error("Error adding category:", error);
      toast.error(error);
    }
  };

  const cancelChange = () => {
    navigate(`/signin`);
  };

  const regex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]).{8,}$/;
  return (
    <div>
      <form onSubmit={(event) => handleSubmit(event, onSubmit)}>
        <div>
          <div className="mt-2">
            <Input
              label={"New Password"}
              labelClasses={"!text-xs"}
              inputType={"newPassword"}
              inputPlaceholder={"Enter your new password"}
              value={values.newPassword}
              inputName={"newPassword"}
              onChange={(e) => {
                handleChange(e);
                setPassword(e.target.value);
              }}
              {...formValidate(errors, "newPassword")}
            />
          </div>
          <div className="mt-1">
            {password && !regex.test(password) && (
              <span className="text-red-600 text-xs">
                Password should be minimum 8 character (1 number, 1 special character, 1 capital letter and 1 small
                letter)
              </span>
            )}
          </div>
        </div>

        <div>
          <div className="flex items-center justify-between"></div>
          <div className="mt-2">
            <Input
              label={"Confirm Password"}
              labelClasses={"!text-xs"}
              inputType={"password"}
              inputPlaceholder={"Enter your confirm password"}
              value={values.confirmPassword}
              inputName={"confirmPassword"}
              onChange={(e) => {
                handleChange(e);
                setConfirmPassword(e);
              }}
              {...formValidate(errors, "confirmPassword")}
            />
          </div>
        </div>

        <div className="mt-2">
          <Button
            buttonClasses={"w-full text-white  hover:bg-primary-two focus-visible:outline  justify-center rounded-md"}
            buttonHasLink={false}
            buttonType={"submit"}
            buttonLabel={"Save Password"}
          />
        </div>
      </form>
    </div>
  );
};

export default ChangePassword;
