import React, { useEffect, useState } from "react";
import Image from "../elements/Image";
import ProfilePhoto from "../../assets/noProfile.jpg";

const UserDetails = ({ data }) => {
  const [details, setDetails] = useState({});

  console.log(details, "details");
  useEffect(() => {
    if (data?._id) {
      setDetails(data?.userDetail || {});
    }
  }, [data?._id]);

  return (
    <>
      {/* <div className="max-w-sm mx-auto bg-white shadow-lg rounded-lg"> */}
      <div className="sm:flex sm:items-start gap-5">
        <div className="sm:flex-shrink-0 rounded-md h-48 w-48 overflow-hidden">
          <Image
            className="object-cover"
            src={details?.image || ProfilePhoto}
            // src={"https://letsenhance.io/static/8f5e523ee6b2479e26ecc91b9c25261e/1015f/MainAfter.jpg"}
            alt={`${details?.name}'s profile picture`}
          />
        </div>
        <div className="relative  ">
          <div className="uppercase tracking-widest text-xs text-victoria font-medium">
            {details?.positionDetail?.title || "N/A"}
          </div>
          <h2 className="text-lg font-medium text-slate-900">{details?.name}</h2>
          <p className="mb-1 text-gray-500 text-sm">
            <span className="font-medium">Email : </span>
            {details?.email}
          </p>
          <p className="mb-1 text-gray-500 text-sm">
            <span className="font-medium">Date of Birth : </span>
            {details?.dateOfBirth ? new Date(details?.dateOfBirth).toLocaleDateString() : "N/A"}
          </p>
          <p className="text-gray-500 text-sm">
            <span className="font-medium">Phone : </span>
            {details?.phone || "N/A"}
          </p>
        </div>
      </div>
      {data?.parentId && (
        <p className="mt-4 text-center pt-2 border-t border-org-border text-slate-600 text-xs font-normal">
          <span className="text-slate-800 font-medium">Reports to : </span>
          {data?.reportToDetail?.name && data?.reportToDetail?.designation
            ? data.reportToDetail.name + ", " + data.reportToDetail.designation
            : "N/A"}
        </p>
      )}
      {/* </div> */}
    </>
  );
};

export default UserDetails;
