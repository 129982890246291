import React, { useEffect, useState } from "react";
import SidebarMenuBlocks from "../components/navigation/SidebarMenuBlocks";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Logo from "../assets/mylogo.png";
import SidebarMenuFront from "../components/frontNavigation/SidebarMenuFront";
import Button from "../components/form/Button";
import UserDropdown from "./UserDropdown";
import LogoutModal from "../components/elements/logoutModal/LogoutModal";

// import { getRole } from "../utils/function";
// import { roleCode } from "../utils/enum";

const DefaultFrontSidebar = ({ userData, isMenuOpen, settings, setIsMenuOpen = () => {} }) => {
  //   const { user } = useSelector((state) => state.user);
  const [sideBar, setSidebar] = useState([]);
  // console.log(isMenuOpen, 'isMenuOpenisMenuOpenisMenuOpen');

  const adminSidebarMenu = [
    {
      _id: "1",
      menuData: [
        {
          _id: 1,
          menuLabel: "Chart",
          menuLink: "/",
          menuIcon: "fa-solid fa-chart-simple",
          menuIconColor: "text-rose-500",
        },
        {
          _id: 2,
          menuLabel: "Employees",
          menuLink: "/people",
          menuIcon: "fa-solid fa-users",
          menuIconColor: "text-fuchsia-500",
        },
        {
          _id: 3,
          menuLabel: "View Company Details",
          menuLink: "/company/details",
          menuIcon: "fa-solid fa-circle-info",
          menuIconColor: "text-sky-500",
        },
      ],
    },
  ];

  const navigate = useNavigate();
  const [isOpen, setOpen] = useState(false);

  const [adminData, setAdminData] = useState({});

  useEffect(() => {
    const dataFromLocalStorage = localStorage.getItem("adminData");
    if (dataFromLocalStorage) {
      const parsedData = JSON.parse(dataFromLocalStorage);
      setAdminData(parsedData);
    }
  }, []);
  // /Direct login Api call Back to super admin
  const onSubmit = async (values) => {
    if (values?._id && values?.uniqueId) {
      localStorage.setItem("auth_token", values?.adminToken);
      localStorage.setItem("refreshToken", values.adminRefreshToken);
      localStorage.setItem("role", "superadmin");
      navigate("/admin/administrator");
    }
  };

  const menuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const dropdownMenuFront = [
    {
      _id: 1,
      label: "My Profile",
      link: "/myProfile",
      icon: "fa-solid fa-user",
    },
    // {
    //   _id: 2,
    //   label: "Employees",
    //   link: "/people",
    //   icon: "fa-regular fa-users",
    // },
    {
      _id: 2,
      label: "Log Out",
      icon: "fa-regular fa-fw fa-arrow-right-from-arc",
      onclick: (val) => {
        setOpen(true);
      },
    },
  ];
  return (
    <>
      <div className="relative w-full pt-lg-16 pt-8 lg:pt-0 h-screen top-0 left-0 bg-slate-900 shadow-lg">
        <div className="block lg:hidden absolute top-2.5 left-2.5">
          <Button
            buttonHasLink={false}
            buttonIcon={!isMenuOpen ? "fa-duotone fa-backward" : "fa-duotone fa-forward"}
            buttonIconPosition={"right"}
            buttonType={"button"}
            buttonClasses={
              "!text-victoria !bg-transparent border-transparent hover:!bg-org-theme hover:!text-org-silver !text-2xl "
            }
            buttonFunction={menuToggle}
          />
        </div>

        <div
          className={
            `relative flex items-center gap-4 h-16 transition-all duration-100 ${null} ` +
            (isMenuOpen ? "px-3" : "px-4")
          }
        >
          <Link className="w-10 h-10 min-w-[40px] " to={"/"} replace>
            <img className="w-full h-full object-contain rounded-full" src={settings?.logo} alt={"Logo"} />
          </Link>

          {!isMenuOpen && (
            <Link className="w-full" to={"/"} replace>
              <div className="text-lg text-white font-bold leading-tight">{settings?.title}</div>
            </Link>
          )}

          {/* <div className='absolute lg:right-0 lg:top-11 -top-5 -right-7 flex gap-2'>
            <Button
              buttonHasLink={false}
              buttonIcon={!isMenuOpen?'fa-solid fa-arrow-left' :"fa-solid fa-arrow-right"}
              buttonIconPosition={'left'}
              buttonType={'button'}
              buttonClasses={
                '!bg-org-theme !border-org-theme !text-white hover:!bg-white !border-org-theme  hover:!text-org-theme rounded-full !absolute !top-0 !right-0 !translate-x-5 !z-15'
              }
              buttonLabelClasses={'font-medium !text-sm'}
              buttonFunction={menuToggle}
            />
          </div> */}
        </div>

        <div className="flex flex-col max-h-[calc(100vh-4rem)] h-full">
          <div
            className={
              "relative overflow-auto scroll-smooth scrollbar scrollbar-1  transition-all duration-100 divide-y " +
              (isMenuOpen ? " divide-slate-800" : "divide-transparent")
            }
          >
            {Array.isArray(sideBar) &&
              adminSidebarMenu.map((item, index) => (
                <SidebarMenuFront
                  key={item._id}
                  menuTitle={item?.menuTitle ? item?.menuTitle : null}
                  menuData={item.menuData}
                  isMenuOpen={isMenuOpen}
                />
              ))}
          </div>

          <div className="relative mt-auto space-y-4">
            {/* <div className='relative flex justify-center mb-6'>
              <UserDropdown
                xPlacement={'right'}
                data={dropdownMenuFront}
                userData={userData}
                isMenuOpen={isMenuOpen}
              />
            </div> */}

            {adminData && adminData?._id && (
              <>
                <div className="flex justify-center mt-12">
                  {!isMenuOpen ? (
                    <>
                      <Button
                        buttonHasLink={false}
                        buttonIcon={"fa-solid fa-left-to-bracket"}
                        buttonIconPosition={"left"}
                        buttonClasses={
                          "hover:!bg-org-theme !border-org-theme hover:!text-white !bg-white !border-org-theme  !text-org-theme mb-16 !px-12 !rounded"
                        }
                        buttonLabel={"Back to Admin"}
                        buttonLabelClasses={"font-medium !text-sm"}
                        buttonFunction={() => onSubmit(adminData)}
                      />
                    </>
                  ) : (
                    <>
                      <Button
                        buttonHasLink={false}
                        buttonIcon={"fa-solid fa-left-to-bracket"}
                        buttonIconPosition={"left"}
                        buttonClasses={
                          "hover:!bg-org-theme !border-org-theme hover:!text-white !bg-white !border-org-theme  !text-org-theme mb-16  !rounded"
                        }
                        // buttonLabel={'Back to Admin'}
                        buttonLabelClasses={"font-medium !text-sm"}
                        buttonFunction={() => onSubmit(adminData)}
                      />
                    </>
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <LogoutModal isOpen={isOpen} setOpen={setOpen} />
    </>
  );
};

export default DefaultFrontSidebar;
