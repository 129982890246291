import React, { useState, useRef } from "react";

const OtpInput = ({
  length = 4,
  handleOtpChange = () => {},
  label,
  labelParentClass = "",
  labelChildClass = "",
  enterKeyClicked = () => {},
}) => {
  const [otp, setOtp] = useState(new Array(length).fill(""));
  const inputRefs = useRef([]);

  const handleChange = (index, event) => {
    const value = event.target.value;
    if (isNaN(value)) return;
    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);
    // Move to the next input field
    if (event.target.nextSibling && value !== "") {
      event.target.nextSibling.focus();
    }
    handleOtpChange(newOtp.join(""));
  };

  const handlePaste = (event) => {
    event.preventDefault();
    const pastedData = event.clipboardData
      .getData("Text")
      .slice(0, length)
      .split("");
    const newOtp = [...otp];
    pastedData.forEach((data, index) => {
      if (!isNaN(data)) {
        newOtp[index] = data;
      }
    });
    setOtp(newOtp);
  };

  const handleKeyDown = (index, event) => {
    if (event.key === "Enter") {
      enterKeyClicked();
      return;
    }
    // if (event.key === "Backspace" && index !== 0 && otp[index] === "") {
    //   inputRefs.current[index - 1].focus();
    // }
  };

  return (
    <>
      {label && (
        <div className={`label relative bottom-8 ${labelParentClass}`}>
          <h2 className={`font-bold text-lg text-center ${labelChildClass}`}>
            {label}
          </h2>
        </div>
      )}

      <div className="flex justify-center items-center">
        {otp.map((digit, index) => (
          <input
            key={index}
            ref={(el) => (inputRefs.current[index] = el)}
            type="text"
            maxLength="1"
            value={digit}
            onChange={(e) => handleChange(index, e)}
            onKeyDown={(e) => handleKeyDown(index, e)}
            onPaste={handlePaste}
            className="w-12 h-12 mx-1 text-center border rounded-md text-victoria border-victoria font-bold"
            style={{ fontVariantNumeric: "tabular-nums" }}
          />
        ))}
      </div>
    </>
  );
};

export default OtpInput;
