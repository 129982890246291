import React, { Fragment, useRef, useState } from "react";
import { Popover, Transition } from "@headlessui/react";
import { classNames } from "../../helpers/classNames";
import { bottom, left, right, top } from "@popperjs/core";
import { usePopper } from "react-popper";
import MiniCard from "./MiniCard";
import Button from "../form/Button";

const AddCard = ({
  dropdownData,
  dropdownContainer,
  xPlacement = "left",
  dropdownButtonClasses,
  dropdownButtonIcon = "fa-solid fa-plus",
  onClickFunction = () => {},
  onSameobjectSubmit = () => {},
}) => {
  const popperElRef = useRef(null);
  const [targetElement, setTargetElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);

  const placements = {
    bottom: bottom,
    bottomLeft: "bottom-start",
    bottomRight: "bottom-end",
    top: top,
    topLeft: "top-start",
    topRight: "top-end",
    right: right,
    left: left,
    "": bottom,
  };

  const { styles, attributes } = usePopper(targetElement, popperElement, {
    placement: placements[xPlacement || ""],
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [0, 4],
        },
      },
    ],
  });
  return (
    <>
      <Popover className="relative">
        {({ open }) => (
          <>
            <Popover.Button
              onClick={() => onClickFunction(xPlacement)}
              ref={setTargetElement}
              className={classNames(
                "flex items-center justify-center h-6 w-6 flex-shrink-0 bg-white border border-slate-200 text-black text-base rounded-full transition-all duration-200",
                dropdownButtonClasses,
                open ? "!opacity-100 !visible" : ""
              )}
            >
              <i className={classNames("fa-fw", dropdownButtonIcon)}></i>
            </Popover.Button>
            <Transition
              as={Fragment}
              className="z-10"
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
              beforeEnter={() => setPopperElement(popperElRef.current)}
              afterLeave={() => setPopperElement(null)}
              ref={popperElRef}
              style={styles.popper}
              {...attributes.popper}
            >
              <Popover.Panel
                className={classNames(
                  "absolute border border-opplio-border w-96 origin-top-right rounded-md bg-white shadow-lg focus:outline-none",
                  dropdownContainer
                )}
              >
                {({ close }) => (
                  <div className="py-2 px-2">
                    {/* <div className="flex pb-2 mb-2 border-b border-slate-200">
                      <Button
                        buttonIcon={"fa-regular fa-plus"}
                        buttonIconPosition={"left"}
                        buttonLabel={"Add Same Object"}
                        buttonClasses={
                          "!w-full !bg-teal-500 !text-black uppercase font-medium"
                        }
                        buttonFunction={async () => {
                          close();
                          onSameobjectSubmit();
                        }}
                      />
                    </div> */}
                    {dropdownData?.length && (
                      <div className="grid grid-cols-4 gap-2">
                        {dropdownData?.map((item, index) => (
                          <button
                            key={index}
                            type="button"
                            className=""
                            onClick={() => {
                              close();
                              item.click();
                            }}
                          >
                            <MiniCard styleType={item.type} />
                          </button>
                        ))}
                      </div>
                    )}
                  </div>
                )}
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    </>
  );
};

export default AddCard;
