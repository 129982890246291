import React, { useEffect, useState } from "react";
import useForm from "../hooks/useForm";
import Input from "../components/form/Input";
import DatePickerInput from "../components/form/DatePickerInput";
import Button from "../components/form/Button";
import { Link, useNavigate } from "react-router-dom";
import { formValidate } from "../helpers/formValidate";
import SingleImageUpload from "../components/form/SingleImageUpload";
import { getProfileDetails, updateProfile } from "../services/ProfileService";
import { useDispatch, useSelector } from "react-redux";
import { setProfileDetails } from "../store/Profile/profileSlice";
import { toast } from "react-toastify";
import Sidebar from "../shared/Sidebar";
import dayjs from "dayjs";
const MyProfile = () => {
  const validation = {
    name: { required: true, message: "Please enter your name" },
    email: { required: true, message: "Please enter your email" },
    designation: { required: true, message: "Please enter your designation" },
    phone: { required: true, message: "Please enter your phone no" },
  };
  const { values, handleChange, handleSubmit, errors, setFieldsValue } = useForm({}, validation);
  const [imageUrl, setImageUrl] = useState("");
  const [resData, setResData] = useState({});
  const [loaded, setLoaded] = useState(false);
  const [date, setDate] = useState({ startDate: null, endDate: null });
  const dispatch = useDispatch();
  const profile = useSelector((state) => state?.profile?.profileData);
  const navigate = useNavigate();
  useEffect(() => {
    if (resData) {
      setFieldsValue({
        name: resData?.name || "",
        email: resData?.email || "",
        designation: resData?.designation || "",
        phone: resData?.phone || "",
      });
      setImageUrl(resData?.image?.url);
      setDate({
        startDate: resData?.dateOfBirth,
        endDate: resData?.dateOfBirth,
      });
    }
  }, [resData]);

  const onSubmit = (values) => {
    updateProfile({
      id: profile?._id,
      name: values?.name,
      email: values?.email,
      designation: values?.designation,
      dateOfBirth: date?.startDate,
      image: imageUrl,
      currentPassword: values?.currentPassword,
      phone: values?.phone,
      newPassword: values?.newPassword,
      confirmPassword: values?.confirmPassword,
    })
      .then((res) => {
        const data = res.data.data;
        if (res?.data?.status === 200 || res?.data?.status === 201) {
          dispatch(setProfileDetails(res?.data?.data));
          toast.success(res?.data?.message);
          setFieldsValue({
            currentPassword: "",
            newPassword: "",
            confirmPassword: "",
          });
        } else if (res?.data?.status === 400) {
          toast.info(res?.data?.message);
        } else {
          toast.info(res?.data?.error);
        }
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    getProfileDetails(setResData, setLoaded);
  }, []);

  return (
    <>
      <section className="relative py-md-12 py-0 bg-slate-50">
        <div className="w-full px-md-4 px-0 sm:max-w-xl md:max-w-3xl lg:max-w-5xl xl:max-w-7xl 2xl:max-w-8xl 3xl:max-w-9xl mx-auto mt-10">
          <div className="grid grid-cols-12 gap-8 md:pt-9 pt-0">
            <div className="relative lg:col-span-3 md:col-span-6 col-span-12 h-full">
              <Sidebar profile={profile} />
            </div>
            <div className="relative lg:col-span-9 md:col-span-12 col-span-12">
              <form onSubmit={(event) => handleSubmit(event, onSubmit)}>
                <div className="relative bg-white shadow rounded-lg  py-4 px-md-8 px-5">
                  <div className="flex justify-between items-center border-b border-slate-200">
                    <h1 className=" flex justify-start text-3xl font-semibold text-victoria py-2">My Profile</h1>
                    <div className=" flex justify-end ">
                      <Button
                        buttonHasLink={false}
                        buttonType={"submit"}
                        buttonIcon={"fa-light fa-floppy-disk"}
                        buttonIconPosition={"left"}
                        buttonClasses={"!bg-org-theme !text-org-silver"}
                        buttonLabel={"Save"}
                        // isDisable={loaded ? false : true}
                      />
                    </div>
                  </div>
                  <div className="divide-y divide-slate-200">
                    <div className="relative py-8">
                      {/* <div className="flex justify-between items-center mb-4">
              <h1 className="text-xl font-semibold text-black">
                Personal Informations
              </h1>
            </div> */}

                      <div className="w-full lg:px-8 md:px-0">
                        <div className="grid grid-cols-4 gap-4 divide-x divide-slate-200">
                          <div className="lg:col-span-3 md:col-span-12 col-span-12">
                            <div className="grid grid-cols-12 gap-4">
                              <div className="relative md:col-span-6 col-span-12">
                                <Input
                                  isInputGroup={true}
                                  inputGroupIcon={"fa-regular fa-user"}
                                  inputGroupPosition={"left"}
                                  label={"Name"}
                                  labelClasses={"!text-base"}
                                  inputType={"text"}
                                  value={values.name}
                                  inputName={"name"}
                                  onChange={handleChange}
                                  {...formValidate(errors, "name")}
                                />
                              </div>
                              <div className="relative md:col-span-6 col-span-12">
                                <Input
                                  isInputGroup={true}
                                  inputGroupIcon={"fa-solid fa-envelope"}
                                  inputGroupPosition={"left"}
                                  label={"Email"}
                                  labelClasses={"!text-base"}
                                  inputType={"text"}
                                  value={values.email}
                                  inputName={"email"}
                                  readOnly
                                />
                              </div>
                              <div className="relative md:col-span-6 col-span-12">
                                <Input
                                  isInputGroup={true}
                                  inputGroupIcon={"fa-solid fa-pen-nib"}
                                  inputGroupPosition={"left"}
                                  label={"Designation"}
                                  labelClasses={"!text-base"}
                                  inputType={"text"}
                                  value={values.designation}
                                  inputName={"designation"}
                                  onChange={handleChange}
                                  {...formValidate(errors, "designation")}
                                />
                              </div>
                              <div className="relative md:col-span-6 col-span-12">
                                <DatePickerInput
                                  label="Date Of Birth"
                                  labelClasses={"text-slate-500 "}
                                  asSingle={true}
                                  useRange={false}
                                  value={date}
                                  onChange={(val) => setDate(val)}
                                />
                              </div>
                              <div className="relative md:col-span-6 col-span-12">
                                <Input
                                  isInputGroup={true}
                                  inputGroupIcon={"fa-solid fa-phone"}
                                  inputGroupPosition={"left"}
                                  label={"Phone No"}
                                  labelClasses={"!text-base"}
                                  inputType={"text"}
                                  value={values.phone}
                                  inputName={"phone"}
                                  onChange={handleChange}
                                  {...formValidate(errors, "phone")}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-span-1 pl-4">
                            <div className="relative">
                              <SingleImageUpload
                                label={"Image"}
                                size={"lg"}
                                image={imageUrl}
                                // image={"https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885_1280.jpg"}
                                setImage={setImageUrl}
                                accept={["jpg", "png", "jpeg"]}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="flex justify-between items-center border-b border-slate-200">
                        <h1 className="text-3xl font-semibold text-victoria py-2">Change Password</h1>
                      </div>
                      <div className="grid grid-cols-12 gap-4">
                        <div className="relative lg:col-span-4 md:col-span-6 col-span-12">
                          <Input
                            isInputGroup={true}
                            inputGroupIcon={"fa-solid fa-key"}
                            inputGroupPosition={"left"}
                            label={"Old Password"}
                            inputPlaceholder={"Enter  Password"}
                            labelClasses={"!text-base"}
                            inputType={"password"}
                            value={values.currentPassword}
                            inputName={"currentPassword"}
                            onChange={handleChange}
                            {...formValidate(errors, "currentPassword")}
                          />
                        </div>
                        <div className="relative lg:col-span-4 md:col-span-6 col-span-12">
                          <Input
                            isInputGroup={true}
                            inputGroupIcon={"fa-solid fa-key"}
                            inputGroupPosition={"left"}
                            label={"New Password"}
                            inputPlaceholder={"Enter  Password"}
                            labelClasses={"!text-base"}
                            inputType={"password"}
                            value={values.newPassword}
                            inputName={"newPassword"}
                            onChange={handleChange}
                            {...formValidate(errors, "newPassword")}
                          />
                        </div>
                        <div className="relative lg:col-span-4 md:col-span-6 col-span-12">
                          <Input
                            isInputGroup={true}
                            inputGroupIcon={"fa-solid fa-key"}
                            inputGroupPosition={"left"}
                            label={"Confirm Password"}
                            inputPlaceholder={"Enter Confirm Password"}
                            labelClasses={"!text-base"}
                            inputType={"password"}
                            value={values.confirmPassword}
                            inputName={"confirmPassword"}
                            onChange={handleChange}
                            {...formValidate(errors, "confirmPassword")}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default MyProfile;
