import React, { useRef, useState } from "react";
import dayjs from "dayjs";
import { Link } from "react-router-dom";
import { classNames } from "../helpers/classNames";
import LogoutModal from "../components/elements/logoutModal/LogoutModal";

const Sidebar = ({ profile }) => {
  const [isOpen, setOpen] = useState(false);
  const userMenu = {
    image: profile?.image?.url
      ? profile?.image?.url
      : "https://e0.pxfuel.com/wallpapers/53/816/desktop-wallpaper-profile-pic-for-girl-stylish-girls.jpg",
    name: profile?.name ? profile?.name : "N/A",
    email: profile?.email ? profile?.email : "N/A",
    phone: profile?.phone ? profile?.phone : "N/A",
    since: dayjs(profile?.dateOfBirth).format("DD MMM, YYYY"),
    designation: profile?.designation ? profile?.designation : "N/A",
    menuData: [
      // {
      //   label: "Chart",
      //   icon: "fa-sharp fa-solid fa-chart-network",
      //   link: "/",
      //   standout: false,
      // },
      // {
      //   label: "My Profile",
      //   icon: "fa-user-large",
      //   link: "/myProfile",
      //   standout: false,
      // },
      // {
      //   label: "Change Password",
      //   icon: "fa-globe",
      //   link: "/",
      //   standout: false,
      // },
      {
        label: "Logout",
        icon: "fa-arrow-right-from-bracket",
        onclick: () => setOpen(true),
        standout: true,
      },
    ],
  };

  return (
    <>
      <div className="sticky top-32 w-full">
        <div className="relative bg-white shadow rounded-lg overflow-hidden pt-4 ">
          <div className="px-3 space-y-4">
            <div className="relative w-full h-60 overflow-hidden rounded-lg">
              <img src={userMenu?.image} alt={userMenu?.name} className={"w-full h-full object-cover"} />
            </div>
            <div className="text-victoria space-y-2 px-2 pb-4">
              <h2 className="text-xl font-bold mb-1 text-slate-700">{userMenu?.name}</h2>
              <div className="flex  gap-1">
                <div className="leading-tight text-sm font-medium text-slate-700">Email :</div>
                <div className="leading-tight text-sm font-normal text-slate-600">{userMenu?.email}</div>
              </div>
              <div className="flex  gap-1">
                <div className="leading-tight text-sm font-medium text-slate-700">Phone :</div>
                <div className="leading-tight text-sm font-normal text-slate-600">{userMenu?.phone}</div>
              </div>
              <div className="flex  gap-1">
                <div className="leading-tight text-sm font-medium text-slate-700">DOB :</div>
                <div className="leading-tight text-sm font-normal text-slate-600">{userMenu?.since}</div>
              </div>
              <div className="flex  gap-1">
                <div className="leading-tight text-sm font-medium text-slate-700">Designation :</div>
                <div className="leading-tight text-sm font-normal text-slate-600">{userMenu?.designation}</div>
              </div>
            </div>
          </div>
          <div className="border-t border-slate-200">
            {userMenu.menuData?.map((item, index) => (
              <div key={index}>
                {item?.link ? (
                  <Link
                    to={item?.link}
                    className={classNames(
                      "flex items-center py-2 px-8 gap-3 font-medium transition-all duration-200 text-black hover:text-victoria bg-magenta-100 text-magenta-500"
                    )}
                  >
                    <div className="w-5 h-5 flex items-center justify-center text-base">
                      <i className={"fa-regular fa-fw " + item.icon}></i>
                    </div>
                    <div className="text-lg">{item.label}</div>
                  </Link>
                ) : (
                  <button
                    type="button"
                    className={classNames(
                      "w-full flex items-center py-2 px-5 gap-3 font-medium transition-all duration-200 text-black hover:text-magenta-500"

                      // ? "bg-magenta-100 text-magenta-500"
                    )}
                    onClick={item.onclick}
                  >
                    <div className="w-5 h-5 flex items-center justify-center text-base">
                      <i className={"fa-regular fa-fw " + item.icon}></i>
                    </div>
                    <div className="text-lg">{item.label}</div>
                  </button>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
      <LogoutModal isOpen={isOpen} setOpen={setOpen} />
    </>
  );
};

export default Sidebar;
