import React from "react";
import { NavLink } from "react-router-dom";

const SidebarMenuItemFront = ({ menuLink, menuIcon, menuIconColor, menuLabel, isMenuOpen, ...props }) => {
  return (
    <>
      <NavLink
        to={menuLink}
        className={({ isActive }) =>
          "group flex items-center gap-1 hover:bg-victoria rounded overflow-hidden p-1 transition-all duration-200 text-white font-md !hover:text-white mt-8" +
          (isActive ? " !bg-victoria !text-org-silver" : "")
        }
      >
        {({ isActive }) => (
          <>
            <div
              className={
                "w-8 h-8 flex justify-center items-center group-hover:text-white text-lg transition-all duration-200 " +
                menuIconColor +
                (isActive ? " !text-org-silver" : "")
              }
            >
              <i className={"fa-light fa-fw " + menuIcon}></i>
            </div>
            {!isMenuOpen && (
              <div className={" text-sm group-hover:text-white " + (isActive ? " !bg-victoria !text-org-silver" : "")}>
                {menuLabel}
              </div>
            )}
          </>
        )}
      </NavLink>
    </>
  );
};

export default SidebarMenuItemFront;
