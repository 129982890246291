import React, { useState } from "react";
import CsvUploader from "../../../components/form/CsvUploader";
import Button from "../../../components/form/Button";
import { toast } from "react-toastify";
import { addCsv } from "../../../services/CsvUploadService";

const UploadCsv = ({ setCsvModalOpen = () => {}, setCount = () => {} }) => {
  const [csvFile, setCsvFile] = useState(null);
  const [loaded, setLoaded] = useState(true);

  const onSubmit = async (values) => {
    if (!csvFile) {
      toast.error("Please upload a CSV file before submitting.");
      return;
    }
    setLoaded(false);
    try {
      await addCsv({
        csv: csvFile,
      }).then((res) => {
        if (res?.data.success) {
          toast.info(res?.data?.message);
          setCsvModalOpen(false);
          setCsvFile(null);
          setCount((pre) => pre + 1);
        } else {
          toast.error(res?.data?.error);
        }
      });
      setLoaded(true);
    } catch (error) {
      // console.error("Error adding category:", error);
      setLoaded(true);
      toast.error(error);
    }
  };
  return (
    <>
      <div className="flex flex-col items-center">
        <div className="w-full flex justify-center">
          <CsvUploader
            label={"Upload Csv"}
            size={"lg"}
            file={csvFile}
            setFile={setCsvFile}
            accept={[".csv"]}
          />
        </div>
        <div className="mt-8 pt-4 border-t border-slate-200 w-full flex justify-end">
          <div className="flex items-center space-x-3">
            <Button
              buttonHasLink={false}
              buttonIcon={"fa-regular fa-xmark"}
              buttonIconPosition={"left"}
              buttonClasses={"!bg-org-silver !border-org-theme !text-org-theme"}
              buttonLabel={"Cancel"}
              buttonLabelClasses={"font-medium !text-sm"}
              buttonFunction={() => {
                setCsvModalOpen(false);
                setCsvFile(null);
              }}
            />
            <Button
              buttonHasLink={false}
              buttonIcon={"fa-light fa-floppy-disk"}
              buttonIconPosition={"left"}
              buttonClasses={"!bg-org-theme !border-org-theme !text-org-silver"}
              buttonLabel={"Submit"}
              buttonLabelClasses={"font-medium !text-sm"}
              buttonFunction={onSubmit}
              isDisable={loaded ? false : true}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default UploadCsv;
